import $ from "jquery";
import slick from "slick-carousel";
import "slick-carousel/slick/slick-theme.scss";
import "slick-carousel/slick/slick.scss";

$("[data-slider='photo-slider']").slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows:true,
  touchMove: true,
  infinite: false,
  lazyLoad: 'ondemand'
});
