import {MDCFormField} from '@material/form-field';
import {MDCCheckbox} from '@material/checkbox';
import {MDCRipple} from '@material/ripple';
import {MDCDialog} from '@material/dialog';

if (document.querySelector('.form-inquiry__submit') != null) {
  const checkbox = new MDCCheckbox(document.querySelector('.mdc-checkbox'));
  const formField = new MDCFormField(document.querySelector('.mdc-form-field'));
  
  new MDCRipple(document.querySelector('.form-inquiry__submit'));
  
  formField.input = checkbox;
}

if (document.querySelector('.mdc-icon-button__favorite') != null) {
  const dialog = new MDCDialog(document.querySelector('.mdc-dialog'));
  const favoriteButtons = document.querySelectorAll('.mdc-icon-button__favorite');

  favoriteButtons.forEach(favoriteButton => {
    favoriteButton.addEventListener('click', (e) => {
      let globalKey = e.currentTarget.getAttribute('data-global-key')
      let buttonAccept = document.querySelector('.mdc-dialog__button-accept');
      buttonAccept.setAttribute('data-global-key', globalKey)
      dialog.open()
    });
  });
}
