import {MDCTopAppBar} from "@material/top-app-bar";
import {MDCDrawer}    from "@material/drawer";

if (document.querySelector('.menu-drawer') != null) {
  const menuDrawer = MDCDrawer.attachTo(document.querySelector('.menu-drawer'));

  const menuList = document.querySelector('.menu-drawer .menu-list');
  menuList.addEventListener('click', (event) => {
    menuDrawer.open = false;
  });

  const closeButton = document.querySelector('.menu-drawer__close-button');
  closeButton.addEventListener('click', (event) => {
    menuDrawer.open = false;
  });

  const topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar'));
  topAppBar.setScrollTarget(document.getElementById('main-content'));
  topAppBar.listen('MDCTopAppBar:nav', () => {
    menuDrawer.open = !menuDrawer.open;
  });

  const iconActiveRightMenu = document.getElementById('icon-active-right-menu')
  if (iconActiveRightMenu) {
    iconActiveRightMenu.addEventListener('click', () => {
      menuDrawer.open = true;
    })
  }
}

const container = document.querySelector(".app .main-container");
const menuSearchOverlay = document.querySelector("[data-menu-search='overlay-close']");
const menuSearchContainer = document.querySelector("[data-menu-search='menu-search-container']");
const inputTriggerMenuSearch = document.getElementById('input-trigger-menu-search');
const inputTriggerMenuSearchMore = document.getElementById('input-trigger-menu-search-more');
const inputTriggerMenuSearchChat = document.getElementById('input-trigger-menu-search-chat');

if (inputTriggerMenuSearch != null) {
  inputTriggerMenuSearch.addEventListener('click', () => {
    activeMenuSearch()
  });
}

if (inputTriggerMenuSearchMore != null) {
  inputTriggerMenuSearchMore.addEventListener('click', () => {
    activeMenuSearch()
  });
}

if (inputTriggerMenuSearchChat != null) {
  inputTriggerMenuSearchChat.addEventListener('click', () => {
    activeMenuSearch()
  });
}

if (inputTriggerMenuSearch != null || inputTriggerMenuSearchMore != null || inputTriggerMenuSearchChat != null) {
  menuSearchOverlay.addEventListener('click', () => {
    menuSearchContainer.classList.remove('active');
    menuSearchOverlay.classList.remove('active');
    document.body.style.overflow = 'auto'
    container.style.setProperty('overflow-y', 'auto', 'important')
  });
}

function activeMenuSearch() {
  menuSearchContainer.classList.add('active');
  menuSearchOverlay.classList.add('active')
  document.body.style.overflow = 'hidden'
  container.style.setProperty('overflow-y', 'hidden', 'important')
}
