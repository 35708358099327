import { Controller } from 'stimulus'
import $ from 'jquery'
import { ajax } from '@rails/ujs'
import { MDCCircularProgress } from '@material/circular-progress';
import searchBreadcrumbsControllable from '../components/search_breadcrumbs'

require('select2/dist/css/select2')

import Select2 from "select2"
import { updateFavoriteBadge } from '../components/favorite_badge.js'
const circularProgress = new MDCCircularProgress(document.querySelector('.mdc-circular-progress'));

export default class extends Controller {
  static targets = ['hiddenQuickSearchInputKeyword', 'sortWrapper', 'carsWrapper', 'hiddenUrl']

  connect() {
    let self = this;
    let inputValues = self.hiddenQuickSearchInputKeywordTarget.value;
    if (window.innerWidth > 649) { // breakpoint of pc
      document.querySelector('.main-container').addEventListener('scroll', (e) => {
        self.loadMore(e);
      })
    }

    const commontAttr = {
      theme: 'classic',
      width: '100%',
      multiple: true,
      language: {
        noResults: function () { return ''; },
        maximumSelected: function () { return ''; }
      },
      minimumResultsForSearch: Infinity,
      placeholder: '',
    }

    $('.suggest-keyword').select2({
      ajax: {
        url: '/suggestions',
        dataType: 'json',
        data: function (params) {
          return Object.assign({}, { keyword: params.term, suggested: $('.suggest-keyword').select2('val'), column_names: ['keyword', 'prefecture_name'] });
        },
        processResults: function (data) {
          return {
            results: data.map(item => {
              return { id: item.suggest_field, text: item.suggest_field };
            })
          };
        },
        cache: false
      },
      tags: true,
      createTag: function (params) {
        var term = $.trim(params.term);
        if ($('.suggest-keyword').val().includes(term)) {
          return null;
        } else {
          return {
            id: term,
            text: term
          }
        }
      },
      ...commontAttr,
    });

    $(document.body).on('change', '.suggest-keyword', function(e) {
      let keyword = $('.suggest-keyword').select2('val');
      if (keyword) {
        self.hiddenQuickSearchInputKeywordTarget.value = keyword.join(' ');
      }
    });
  }

  reloadMenuSearch() {
    var url = new URL(this.hiddenUrlTarget.value)
    this.menuSearchController.reloadMenuSearch(url)
  }

  get menuSearchController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById('menu-search'), "menu-search")
  }

  loadMore(event) {
    var scrollH      = 0
    var height       = 0
    var innerHeight  = 0
    var controller   = this;
    var body         = document.body;
    var innerHeight  = window.innerHeight
    var carsWrapper  = this.carsWrapperTarget;
    var html         = document.documentElement;
    var total        = parseInt(carsWrapper.getAttribute('data-total'));
    var currentItems = carsWrapper.getElementsByClassName('item').length;
    var distanceWithBottom = 100;

    if (event.target.className == 'main-container') {
      scrollH     = event.target.scrollTop;
      height      = event.target.scrollHeight
    } else {
      scrollH     = window.pageYOffset;
      height      = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    }

    var condition = (scrollH + distanceWithBottom >= height - innerHeight)

    if (condition && carsWrapper.getAttribute('data-loading') == 'false' && currentItems < total ) {
      var url = new URL(window.location.href);
      url.searchParams.set('sort', $('.sort-wrapper span.tab.active').attr('data-sort'));
      url.searchParams.set('page', parseInt(carsWrapper.getAttribute('data-page')) + 1);

      ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        beforeSend: function(){
          carsWrapper.setAttribute('data-loading', 'true');
          controller.showLoading();
          return true;
        },
        success: function (response) {
          carsWrapper.insertAdjacentHTML('beforeend', response.html);
          carsWrapper.setAttribute('data-page', response.page);
          UserLog.search_imp(response.ranking_results);
          UserLog.pageTrack();
        },
        complete: function(){
          carsWrapper.setAttribute('data-loading', 'false');
          controller.hideLoading();
        }
      })
    }
    searchBreadcrumbsControllable();
  }

  search(event) {
    var urlParts = decodeURIComponent(location.pathname).split('/').filter(n => n);
    var IsBrowsercarsPage = urlParts.shift() == 'browsercars'
    if (IsBrowsercarsPage) {
      var currentUrl = location.origin + '/search' + decodeURIComponent(location.search);
    } else {
      var currentUrl = location.href;
    }

    var url = new URL(currentUrl);
    if (IsBrowsercarsPage) {
      for (let i = 0; i < urlParts.length; i += 2) {
        url.searchParams.set(urlParts[i], urlParts[i + 1]);
      }
    }

    var inputValue = this.hiddenQuickSearchInputKeywordTarget.value.split(/[\ ,、]+/).join(',');
    var oldKeyword = url.searchParams.get('keyword');
    var newKeyword = '';

    if ((oldKeyword == null || oldKeyword == '') && inputValue == '') {
      url.searchParams.delete('keyword');
    } else if (oldKeyword == null || oldKeyword == '') {
      newKeyword = inputValue;
      url.searchParams.set('keyword', newKeyword);
    } else if (inputValue != '') {
      newKeyword = inputValue + ',' + oldKeyword;
      url.searchParams.set('keyword', $.unique(newKeyword.split(/[　,]+/).sort()).join(','));
    } else {
      url.searchParams.set('keyword', oldKeyword);
    }

    window.location.href = url;
  }

  removeFilter(event) {
    const currentItem = event.currentTarget;
    var url = new URL(this.hiddenUrlTarget.value)
    let dataFilter = currentItem.getAttribute('data-filter')
    let dataRemove = currentItem.getAttribute('data-remove')

    var currentParams = url.searchParams.get(dataFilter)
    if (!currentParams) { return }

    currentParams = currentParams.split(',')

    currentParams = $.grep(currentParams, function(value) {
      return value.indexOf(dataRemove) < 0
    });

    currentParams = currentParams.join(',')
    url.searchParams.delete(dataFilter);
    if (currentParams) {
      url.searchParams.set(dataFilter, currentParams);
    }
    window.location.href = url;
  }

  removeKeyword(event) {
    const currentItem = event.currentTarget;
    var url = new URL(window.location.href);
    var keyWordIndex = currentItem.getAttribute('data-keyword-index');
    var urlParts = location.pathname.split('/').filter(n => n);
    var IsBrowsercarsPage = urlParts[0] == 'browsercars'
    if (IsBrowsercarsPage) {
      var keywordParamIndex = urlParts.indexOf("keyword")
      var oldKeyword = decodeURIComponent(urlParts[keywordParamIndex + 1])
      var keywords = oldKeyword.split(/[　,]+/);
      keywords.splice(keyWordIndex, 1);

      if (keywords.length == 0) {
        urlParts.splice(keywordParamIndex, 2);
      } else {
        urlParts[keywordParamIndex + 1] = keywords.join(',')
      }
      url = location.origin
      for (let i = 0; i < urlParts.length; i += 1) {
        url = url + '/' + urlParts[i];
      }
    } else {
      var oldKeyword = url.searchParams.get('keyword');
      var keywords = oldKeyword.split(/[　,]+/);
      keywords.splice(keyWordIndex, 1);

      if (keywords.length == 0) {
        url.searchParams.delete('keyword');
      } else {
        url.searchParams.set('keyword', keywords.join(','));
      }
    }

    window.location.href = url;
  }

  sort(event) {
    const currentItem = event.currentTarget;
    var controller = this;
    var carsWrapper = this.carsWrapperTarget;
    var url = new URL(window.location.href);
    url.searchParams.set('sort', currentItem.getAttribute('data-sort'));

    ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      beforeSend: function(){
        carsWrapper.setAttribute('data-loading', 'true');
        controller.showLoading();
        return true;
      },
      success: function (response) {
        carsWrapper.innerHTML = response.html;
        carsWrapper.setAttribute('data-page', 0);
        controller.sortWrapperTarget.getElementsByClassName('active')[0].classList.remove('active');
        currentItem.classList.toggle('active');
        controller.scrollToTab();
      },
      complete: function(){
        carsWrapper.setAttribute('data-loading', 'false');
        controller.hideLoading();
      }
    })
  }

  toggleFavorite(event) {
    const currentItem = event.currentTarget;
    let globalKey = currentItem.getAttribute('data-global-key');
    let data = { global_key: globalKey };
    let favorite = (currentItem.getAttribute('data-favorite') === 'true');
    let url = (favorite ? '/cars/ajax_delete_favorite' : '/cars/ajax_add_favorite');

    ajax({
      url: url,
      type: 'POST',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        if (response.status) {
          let class_name = (favorite ? 'favorite_border' : 'favorite');

          currentItem.setAttribute('data-favorite', !favorite);
          currentItem.innerHTML = class_name;
          currentItem.classList.toggle('favorite-on');
          updateFavoriteBadge(response.favorite_count)
          if (!favorite) {
            UserLog.search_result_action('favorite', globalKey);
            UserLog.pageTrack();
          }
        } else {
          let class_name = (favorite ? 'favorite' : 'favorite_border');

          currentItem.setAttribute('data-favorite', favorite);
          currentItem.innerHTML = class_name;
          currentItem.classList.toggle('favorite-on');

          alert(response.message);
        }
      }
    })
  }

  trackingMoveToDetail(event) {
    const currentItem = event.currentTarget;
    let globalKey = currentItem.getAttribute('data-global-key');
    UserLog.search_result_action('detail', globalKey);
    UserLog.pageTrack();
  }

  scrollToTab() {
    this.sortWrapperTarget.scroll({
      left: this.sortWrapperTarget.getElementsByClassName('active')[0].offsetLeft - 20,
      behavior: 'smooth'
    });
  }

  showLoading() {
    document.getElementById('mdc-loader').style.display = 'block';
    circularProgress.foundation.setDeterminate(false);
  }

  hideLoading() {
    document.getElementById('mdc-loader').style.display = 'none';
    circularProgress.foundation.setDeterminate(true);
  }
}
