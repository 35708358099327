export default class SearchParams {
  constructor(params) {
    this.priceMin        = params.priceMin
    this.priceMax        = params.priceMax
    this.makerCodes      = params.makerCodes ?? []
    this.carModelCodes   = params.carModelCodes ?? []
    this.bodytypeCode    = params.bodytypeCode
    this.gradeCodes      = params.gradeCodes ?? []
    this.fmcCodes        = params.fmcCodes ?? []
    this.prefectureCodes = params.prefectureCodes ?? []
    this.cityCodes       = params.cityCodes ?? []
    this.equips          = params.equips ?? []
    this.colorCodes      = params.colorCodes ?? []
    this.bodyTypeDetailNames = params.bodyTypeDetailNames ?? []
    this.engineType          = params.engineType ?? []
    this.carEquipCodes       = params.carEquipCodes ?? []
    this.campingCar          = params.campingCar ?? []
    this.commercialCars      = params.commercialCars ?? []
    this.transmissionTypes   =  params.transmissionTypes ?? []

    this.reset = function() {
      this.priceMin        = undefined
      this.priceMax        = undefined
      this.makerCodes      = []
      this.carModelCodes   = []
      this.bodytypeCode    = undefined
      this.gradeCodes      = []
      this.fmcCodes        = []
      this.prefectureCodes = []
      this.cityCodes       = []
      this.equips          = []
      this.colorCodes      = []
      this.bodyTypeDetailNames = []
      this.engineType          = []
      this.carEquipCodes       = []
      this.campingCar          = []
      this.commercialCars      = []
      this.transmissionTypes   = []
    }

    this.makeParams = function() {
      return JSON.stringify({ price_min: this.priceMin,
                              price_max: this.priceMax,
                              maker: this.makerCodes,
                              car_model: this.carModelCodes,
                              body: this.bodytypeCode,
                              grade: this.gradeCodes,
                              fmc_code: this.fmcCodes,
                              prefecture: this.prefectureCodes,
                              city: this.cityCodes,
                              options: this.equips,
                              color: this.colorCodes,
                              body_type_detail_name: this.bodyTypeDetailNames,
                              engine_type: this.engineType,
                              car_equip: this.carEquipCodes,
                              camping_car: this.campingCar,
                              commercial_car: this.commercialCars,
                              transmission_type: this.transmissionTypes
                            })
    }
  }
}
