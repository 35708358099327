import $ from 'jquery'
import {ajax} from '@rails/ujs'

function UserLog () {
  if (!(this instanceof UserLog)) {
    return new UserLog();
  }

  this.access_log = () => { _kuruma_aq.unshift(['app_access', { search_result_count: $('#search_result_count').val() }]); }

  this.search_imp = seach_imp_log => { _kuruma_aq.unshift(['search_imp_log', { imp_lists: seach_imp_log }]); }

  this.search_result = (es_query) => {
    _kuruma_aq.unshift(['search_result', {
      keyword: detectKeywordInUrl(),
      search_result_count: $('#search_result_count').val(),
      es_query: es_query
    }]);
  }

  this.detail_recommend_imp = detail_recommend_imp_log => { _kuruma_aq.unshift(['detail_recommend_imp_log', { imp_lists: detail_recommend_imp_log }]); }

  this.search_result_action = (actionName, globalKey) => {
    let $sortWrapperActived = $('#main-content .sort-wrapper span.tab.active')
    let dataSort = new URLSearchParams($sortWrapperActived.data('sort')).toString()

    _kuruma_aq.unshift(['search_result_action', {
      keyword: detectKeywordInUrl(),
      sort: dataSort,
      search_result_count: $('#search_result_count').val(),
      action_name: actionName,
      global_key: globalKey
    }]);
  }

  function detectKeywordInUrl() {
    let urlParts = decodeURIComponent(location.pathname).split('/').filter(n => n);
    let IsBrowsercarsPage = urlParts.shift() == 'browsercars'
    if (IsBrowsercarsPage) {
      let indexOfKeyword = urlParts.indexOf('keyword');
      var keyword = indexOfKeyword == -1 ? null : urlParts[indexOfKeyword + 1];
    } else {
      let params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      var keyword = params.keyword;
    }
    return keyword;
  }

  this.pageTrack = () => {
    let regexUserAgent = new RegExp('AdsBot-Google', 'i')
    if (regexUserAgent.test(navigator.userAgent))
      return;

    let postData = {
      ref: document.referrer,
      request_path: location.pathname,
      request_parameter: location.search,
      trackings: []
    }

    _kuruma_aq.forEach(log => {
      postData['trackings'].push({
        url: log[0],
        data: log[1]
      });
    });

    postData['trackings'] = JSON.stringify(postData['trackings'])
    let tracking_req = {
      url: '/log_trackings',
      type: 'POST',
      cache: false,
      dataType: 'jsonp',
      data: new URLSearchParams(postData)
    }
    ajax(tracking_req);
    _kuruma_aq = [];
  }
}

global.UserLog = UserLog();
