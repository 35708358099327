const breadcrumbsControllable = () => {
  if (!document.querySelector('.breadcrumbs-page')) return;

  const arrBreadcrumbs = document.querySelectorAll("[class^=breadcrumbs-page__]");
  let i = arrBreadcrumbs.length;
  let widthBlock = 0;
  for (const el of arrBreadcrumbs) {
    el.style.zIndex = i;
    el.style.left = widthBlock + 'px';

    const decimal = (el.scrollWidth - el.offsetWidth) - (el.scrollWidth - el.getBoundingClientRect().width);
    widthBlock += el.getBoundingClientRect().width + 1 - decimal;
    i--;
  }
}

export default breadcrumbsControllable;
