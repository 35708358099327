import { Controller } from 'stimulus'
import { showPopup } from '../components/prevent_leaving_behavior'
import Cookies from 'js-cookie';
const $ = require('jquery')

export default class extends Controller {
  connect() {
    $('.app').trigger('click')
  }

  initialize() {
    if (Cookies.get('hidePopupPreventLeavingInquiryPage') === undefined) {
      showPopup('popup_prevent_leaving_inquiry_page',
                'popup_prevent_leaving_inquiry_page__overlay',
                'btn-popup-prevent_leaving_inquiry_page--close',
                'mdc-button--hide-popup');
    }
  }
}
