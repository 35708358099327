import { Controller } from 'stimulus'
import Cookies from 'js-cookie';

export default class extends Controller {
  static targets = ['bannerTopPage']

  initialize() {
    if (Cookies.get('hideTopBanner') == 'true') {
      this.bannerTopPageTarget.remove()
    }
  }

  hideBanner() {
    Cookies.set('hideTopBanner', 'true', { expires: 7, path: '/' })
    this.bannerTopPageTarget.remove();
  }
}
