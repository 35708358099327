import { Controller } from 'stimulus'
import {ajax} from '@rails/ujs'
import { updateFavoriteBadge } from '../components/favorite_badge.js'

export default class extends Controller {
  static targets = ['favoriteButton']

  unFavorite(event) {
    const currentItem = event.currentTarget
    let globalKey = currentItem.getAttribute('data-global-key')
    let data  = { global_key: globalKey }

    ajax({
      url: `/cars/ajax_delete_favorite`,
      type: 'POST',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      success: function (data) {
        document.querySelector(`.${globalKey}_container`).remove()
        updateFavoriteBadge(data.favorite_count)
        if (data.favorite_count == 0) { location.reload() }
      }
    })
  }
}
