import { Controller } from "stimulus"
import $ from 'jquery'
export default class extends Controller {
  static targets = ['cbInquiryVisitShop', 'cbInquiryCarStatus', 'cbInquiryOther', 'isIconToggle', 'textAreaInquiryComment',
                    'toggleZipcodeInput', 'togglePrefCityInput', 'prefCityInputField', 'zipcodeInputField',
                    'prefCityErrors', 'zipcodeError']

  connect() {
    this.hanldeToggleInquiryComment()
    this.handleToggledInputField()
    this.cssSelectPlaceholder()
  }

  toggleInquiryComment() {
    this.hanldeToggleInquiryComment()
  }

  iconToggleInquiryComment() {
    if (this.toggleClassCondition()) return;

    this.isIconToggleTarget.classList.toggle('active');
    this.textAreaInquiryCommentTarget.classList.toggle('inactive');
  }

  hanldeToggleInquiryComment() {
    if (this.toggleClassCondition()) {
      this.isIconToggleTarget.classList.add('active');
      this.textAreaInquiryCommentTarget.classList.remove('inactive');
    } else {
      this.isIconToggleTarget.classList.remove('active');
      this.textAreaInquiryCommentTarget.classList.add('inactive');
    }
  }

  toggleClassCondition() {
    let cs_visit_shop_checked = this.cbInquiryVisitShopTarget.checked;
    let cs_car_status_checked = this.cbInquiryCarStatusTarget.checked;
    let cs_other_checked      = this.cbInquiryOtherTarget.checked;

    if (cs_visit_shop_checked || cs_car_status_checked || cs_other_checked) return true;
    false
  }

  format_zipcode(event) {
    let zip_code_input = event.currentTarget.value;

    if (zip_code_input && zip_code_input.length == 7) {
      if (zip_code_input.indexOf('-') == -1) {
        let zip_code = zip_code_input.slice(0, 3) + '-' + zip_code_input.slice(3, zip_code_input.length);
        event.currentTarget.value = zip_code
      }
    }
  }

  toggleInput(event) {
    let target = event.currentTarget.dataset.inquiryTarget
    if (target == 'toggleZipcodeInput') {
      this.prefCityInputFieldTarget.classList.add('hidden')
      this.zipcodeInputFieldTarget.classList.remove('hidden')

      this.toggleZipcodeInputTarget.classList.add('hidden')
      this.togglePrefCityInputTarget.classList.remove('hidden')

      if (this.hasZipcodeErrorTarget) this.zipcodeErrorTarget.classList.remove('hidden')
      if (this.hasPrefCityErrorsTarget) this.prefCityErrorsTargets.forEach(target => target.classList.add('hidden'))

      document.querySelector('#car_inquiry_use_pref_city').value = false
    } else {
      this.prefCityInputFieldTarget.classList.remove('hidden')
      this.zipcodeInputFieldTarget.classList.add('hidden')

      this.toggleZipcodeInputTarget.classList.remove('hidden')
      this.togglePrefCityInputTarget.classList.add('hidden')

      if (this.hasZipcodeErrorTarget) this.zipcodeErrorTarget.classList.add('hidden')
      if (this.hasPrefCityErrorsTarget) this.prefCityErrorsTargets.forEach(target => target.classList.remove('hidden'))

      document.querySelector('#car_inquiry_use_pref_city').value = true
    }
  }

  handleToggledInputField() {
    let use_pref_city = document.querySelector('#car_inquiry_use_pref_city').value
    use_pref_city == 'false' ? this.toggleZipcodeInputTarget.click() : this.togglePrefCityInputTarget.click()
  }

  fetchCities() {
    let prefCode = document.querySelector('#car_inquiry_pref').value
    if (prefCode == '') {
      $("#car_inquiry_city").empty();
      $("#car_inquiry_city").append('<option value>市区町村</option>');
      $('#car_inquiry_pref, #car_inquiry_city').addClass('placeholder')
      return
    }
    $.ajax({
      type: 'GET',
      url: '/ajax_load_inquiry_cities',
      dataType: 'json',
      data: { pref_code: prefCode },
      success: function (response) {
        // Reset dropdown values
        $("#car_inquiry_city").empty();
        $("#car_inquiry_city").append('<option value>市区町村</option>');

        // Assign new values
        for(var i=0; i < response.length; i++){
          $("#car_inquiry_city").append('<option value="' + response[i][1] + '">' + response[i][0] + '</option>');
        }

        $('#car_inquiry_pref').removeClass('placeholder')
      }
    })
  }

  fetchZipcode() {
    let cityCode = event.currentTarget.value
    if (cityCode == '') {
      document.querySelector('#car_inquiry_zip_code').value = ''
      document.querySelector('#car_inquiry_pref_city_name').value = ''
      $('#car_inquiry_city').addClass('placeholder')

      return
    }
    $.ajax({
      type: 'GET',
      url: '/ajax_load_inquiry_zipcode',
      data: { city_code: cityCode },
      dataType: 'json',
      success: function(response) {
        $("#car_inquiry_zip_code").val(response.code)
        $("#car_inquiry_pref_city_name").val(response.address)
        $('#car_inquiry_city').removeClass('placeholder')
      }
    });
  }

  cssSelectPlaceholder() {
    let prefSelect = document.querySelector('#car_inquiry_pref');
    let citySelect = document.querySelector('#car_inquiry_city');
    (prefSelect.value == '') ? prefSelect.classList.add('placeholder') : prefSelect.classList.remove('placeholder');
    (citySelect.value == '') ? citySelect.classList.add('placeholder') : citySelect.classList.remove('placeholder');
  }
}
