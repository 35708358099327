const $ = require('jquery')
const FIRST_STEP = 1
const FINAL_STEP = 7

processes = {
  step_1: {
    bot_message: '最初に「お問い合わせ内容」をお教えください。',
    input_field: 'contents',
    relative_field: 'comment'
  },
  step_2: {
    bot_message: 'あなたのお住いの地域を教えて下さい。</br>販売店が遠方の場合、お見積りが異なることがあるため、ご記入いただいております。',
    user_message: '地域: #{content}',
    input_field: 'pref'
  },
  step_3: {
    bot_message: '市区町村を選んでください。',
    user_message: '市区町村: #{content}',
    input_field: 'city'
  },
  step_4: {
    bot_message: '次に、お客様のお名前をお教えください。',
    input_field: 'name'
  },
  step_5: {
    bot_message: '念のため、お客様のフリガナをご確認させてください。',
    input_field: 'name_kana'
  },
  step_6: {
    bot_message: '最後に、ご連絡先のメールアドレスを教えてください。※メールアドレスは販売店には公開されません。',
    input_field: 'mail'
  },
  step_7: {
    bot_message: ['質問は以上です。', '下記をご確認の上、送信してください。']
  }
}

$(document).ready(function () {
  if ($('.inquiry-chat').length) {
    disableAppBar()
    $('footer').addClass('hidden')
    loadCommentBox(true)
    currentStep = 1
    scrollDown()
    $('.inquiry-chat .inquiry-chat__validate-btn').click()
  }
})

function scrollDown() {
  if (currentStep == FINAL_STEP) return false;
  if (window.innerWidth > 649) return animateScrollDown($('.main-container'));
  animateScrollDown($('html, body'));
}

const animateScrollDown = (el) => el.animate({ scrollTop: $(document).height() }, 1000)

function disableAppBar() {
  if ($('[data-appbar="disable"]').length) {
    app_bar = $('#app-bar')
    original_app_bar = app_bar.clone()
    app_bar.replaceWith(original_app_bar)
    $('.app-bar__logo > a').attr('href', '#')
  }
}

$('.inquiry-chat .comment .toggle-comment').on('click', function () {
  loadCommentBox()
})

$(document).on('change', '#inp_inquiry_other, #inp_inquiry_car_status, #inp_inquiry_visit_shop', function() {
  loadCommentBox()
})

function loadCommentBox(init_status = false) {
  hiddenCommentBoxNumber = $('.comment-input.hidden').length
  if (reasonInquiries()['visit_shop'] || reasonInquiries()['car_status'] || reasonInquiries()['other']) {
    showCommentBox()
  } else if (init_status) {
    hiddenCommentBox()
  } else if (hiddenCommentBoxNumber) {
    showCommentBox()
  } else {
    hiddenCommentBox()
  }
}

function showCommentBox() {
  $('.comment-input').removeClass('hidden')
  $('.inquiry-chat .comment .minus--rotate').addClass('hidden-opacity').removeClass('show-opacity')
}

function hiddenCommentBox() {
  $('.comment-input').addClass('hidden')
  $('.inquiry-chat .comment .minus--rotate').removeClass('hidden-opacity').addClass('show-opacity')
}

function reasonInquiries() {
  return {
    stock: + $('#inp_inquiry_stock').is(':checked'),
    estimate: + $('#inp_inquiry_estimate').is(':checked'),
    visit_shop: + $('#inp_inquiry_visit_shop').is(':checked'),
    car_status: + $('#inp_inquiry_car_status').is(':checked'),
    other: + $('#inp_inquiry_other').is(':checked'),
    comment: $('#inp_inquiry_comment').val()
  }
}

function showError(message) {
  errorElement = $('.inquiry-chat .error-box').clone().removeClass('hidden')
  errorElement.find('.error-message').html(message)
  $('.inquiry-chat .inquiry-chat__bot-message-container[data-step="' + currentStep + '"] > div:nth-child(2)').append(errorElement)
}

function removeAllErrorMessage() {
  $('.inquiry-chat .inquiry-chat__bot-message-container .error-box').remove()
}

function removeUserOldMessage() {
  $('.inquiry-chat__user-message-container[data-step="' + currentStep + '"]').remove()
}

function hiddenUserOldMessage() {
  $('.inquiry-chat__user-message-container[data-step="' + currentStep + '"]').addClass('hidden')
}

function updateCurrentForm() {
  $('.inquiry-chat .current-form .input-box').addClass('hidden')
  $('.inquiry-chat .current-form .input-box.step_' + currentStep).removeClass('hidden')
  if (currentStep == FINAL_STEP) {
    $('.show-in-confirm').removeClass('hidden').addClass('push-up-later')
    $('footer').removeClass('hidden').addClass('push-up-later')
    $('.hidden-in-confirm').addClass('hidden')
  } else {
    $('.hidden-in-confirm').removeClass('hidden')
    $('.show-in-confirm').addClass('hidden')
    $('footer').addClass('hidden')

    // Hide button submit and auto move to next step after selecting
    if (currentStep == 2 || currentStep == 3) {
      $('.inquiry-chat .inquiry-chat__validate-btn').addClass('hidden')
    }
  }
}


['#inp_inquiry_pref', '#inp_inquiry_city'].forEach(field => {
  $(document).on('change', field, function(){
    // Call Ajax to load cities if currentStep is prefecture (2)
    currentStep == 2 ? ajaxLoadCities() : ajaxLoadZipCode()
  })
})

function ajaxLoadCities() {
  var prefCode = $('#inp_inquiry_pref').val()
  if (prefCode == '') return

  $.ajax({
    type: 'GET',
    url: '/ajax_load_inquiry_cities',
    data: { pref_code: prefCode },
    dataType: 'json',
    success: function(response) {
      // Reset dropdown values
      $("#inp_inquiry_city").empty();
      $("#inp_inquiry_city").append('<option value>市区町村を選んでください</option>');

      // Assign new values
      for(var i=0; i < response.length; i++){
        $("#inp_inquiry_city").append('<option value="' + response[i][1] + '">' + response[i][0] + '</option>');
      }

      $('.inquiry-chat .inquiry-chat__validate-btn').click();
    }
  });
}

function ajaxLoadZipCode() {
  var prefCode = $('#inp_inquiry_pref').val()
  var cityCode = $('#inp_inquiry_city').val()
  if (prefCode == '' || cityCode == '') return

  $.ajax({
    type: 'GET',
    url: '/ajax_load_inquiry_zipcode',
    data: { city_code: cityCode },
    dataType: 'json',
    success: function(response) {
      // Fill zipcode value to hidden field
      $("#car_inquiry_zip_code").val(response.code)
      $('.inquiry-chat .inquiry-chat__validate-btn').click();
    }
  });
}

$(document).on('click', '.inquiry-chat .inquiry-chat__validate-btn', function () {
  validate()

  function validate() {
    botErrorMessages = []
    inquiryData = {
      comment: $('#inp_inquiry_comment').val(),
      pref: $('#inp_inquiry_pref').val(),
      city: $('#inp_inquiry_city').val(),
      name: $('#inp_inquiry_name').val(),
      name_kana: $('#inp_inquiry_name_kana').val(),
      mail: $('#inp_inquiry_mail').val()
    }

    Object.keys(reasonInquiries()).forEach(function (key) {
      inquiryData[key] = reasonInquiries()[key]
    })

    function addError(e) {
      errorMessages = JSON.parse(e.responseText)
      currentStepErrors = errorMessages[processes['step_' + currentStep].input_field] || errorMessages[processes['step_' + currentStep].relative_field]
      if (currentStepErrors) {
        botErrorMessages.push(currentStepErrors[0])
      }
    }

    function request_validate() {
      return $.ajax({
        type: 'POST',
        url: '/sp_inquiry_cs_validate',
        data: {
          car_inquiry: inquiryData,
          authenticity_token: $('[name="authenticity_token"]').val()
        }
      })
    }

    $.when(request_validate()).always(function (e) {
      removeAllErrorMessage()
      if (e) {
        addError(e)
      }
      renderInputBoxAndMessage()
    })
  }

  function copyDataAndCreateMessage() {
    if (currentStep == FIRST_STEP) {
      inquiryTextChecked = []
      Object.keys(reasonInquiries()).forEach(function (key) {
        $('#car_inquiry_' + key).val(reasonInquiries()[key])
        if (key != 'comment' && reasonInquiries()[key]) {
          inquiryTextChecked.push($('label[for="inp_inquiry_' + key + '"]').text())
        }
      })
      nextUserMessage = [inquiryTextChecked.join(', ')]
      if ($('#car_inquiry_comment').val()) {
        nextUserMessage.unshift($('#car_inquiry_comment').val())
      }
    } else {
      currentInputField = processes['step_' + currentStep].input_field
      nextUserMessage = [$('#inp_inquiry_' + currentInputField).val()]
      $('#car_inquiry_' + currentInputField).val(nextUserMessage[0])

      if (currentStep == 2 || currentStep == 3) {
        selectedText = $('#inp_inquiry_' + currentInputField).find('option:selected').text()
        nextUserMessage = [processes['step_' + currentStep].user_message.replace('#{content}', selectedText)]
      }
    }
    nextBotMessage = processes['step_' + (+ currentStep + 1)].bot_message
  }

  function renderUserNewMessage() {
    nextUserMessage.forEach(mess => {
      userMessageElement = $('.inquiry-chat .inquiry-chat__user-message-container:last').clone().removeClass('hidden').attr('data-step', currentStep)
      userMessageElement.find('.user-message .inner-content').html(mess)
      $('.inquiry-chat .inquiry-chat__bot-message-container[data-step="' + currentStep + '"]').after(userMessageElement)
    })
  }

  function renderBotNewMessage() {
    messageContainer = $('.inquiry-chat .inquiry-chat__bot-message-container:last').clone().removeClass('hidden').attr('data-step', + currentStep + 1)
    if (Array.isArray(nextBotMessage)) {
      originMessageElement = $('.inquiry-chat .inquiry-chat__bot-message-container:last .bot-message').clone()
      originMessageElement.find('.arrow-left').remove()
      messageContainer.find('.bot-message').remove()

      nextBotMessage.forEach(mess => {
        newMess = originMessageElement.clone()
        newMess.find('.inner-content').html(mess)
        messageContainer.find('> div:last-child').append(newMess)
      })
      messageContainer.find('.bot-message:first-child').append('<div class="arrow-left"></div>')
    } else {
      messageContainer.find('.bot-message .inner-content').html(nextBotMessage)
    }

    setTimeout(() => {
      $('.inquiry-chat > .inquiry-chat__conversation').append(messageContainer)
    }, 1000)
  }

  function renderInputBoxAndMessage() {
    if (botErrorMessages.length) {
      hiddenUserOldMessage()
      showError(botErrorMessages[0])
    } else {
      latestStep = +$('.inquiry-chat__bot-message-container').last().attr('data-step')

      copyDataAndCreateMessage()
      removeUserOldMessage()
      renderUserNewMessage()
      if (currentStep == latestStep) {
        renderBotNewMessage()
        currentStep = latestStep + 1
      } else {
        // In case of edit pref (current step is 2), reload its related cities (step 3)
        if (currentStep == 2) {
          $('.inquiry-chat__bot-message-container[data-step=3]').removeClass('hidden')
          currentStep++
        } else {
          $('.inquiry-chat__bot-message-container[data-step]').removeClass('hidden')
          $('.inquiry-chat__user-message-container[data-step]').removeClass('hidden')
          currentStep = latestStep
        }
      }
      updateCurrentForm()
    }
    scrollDown()
  }
})

function resetCurrentInputValue() {
  beforeEditValue = $('#car_inquiry_' + processes['step_' + currentStep].input_field).val()
  $('#inp_inquiry_' + processes['step_' + currentStep].input_field).val(beforeEditValue)
}

$(document).on('click', '.inquiry-chat .edit', function () {
  resetCurrentInputValue()
  currentStep = +$(this).closest('.inquiry-chat__user-message-container').attr('data-step')
  $('.material-icons.cancel-edit').addClass('hidden')
  $('.material-icons.edit').removeClass('hidden')
  $(this).addClass('hidden')
  $(this).next().removeClass('hidden')
  updateCurrentForm()
  $(this).closest('.inquiry-chat__user-message-container').nextAll().addClass('hidden')
})

$(document).on('click', '.inquiry-chat .cancel-edit', function () {
  resetCurrentInputValue()
  $(this).addClass('hidden')
  $(this).prev().removeClass('hidden')
  $('.inquiry-chat__bot-message-container[data-step]').removeClass('hidden')
  $('.inquiry-chat__user-message-container[data-step]').removeClass('hidden')
  currentStep = +$('.inquiry-chat__bot-message-container').last().attr('data-step')
  updateCurrentForm()
})

$(document).on('click', '.inquiry-chat__submit-btn', function () {
  $('.inquiry-chat__form').submit()
})
