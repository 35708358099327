import { Controller } from 'stimulus'
import $ from 'jquery'
import {ajax} from '@rails/ujs'

require('select2/dist/css/select2')

import Select2 from "select2"

export default class extends Controller {
  static targets = ['hiddenQuickSearchInputPrefectureCodes', 'hiddenQuickSearchInputCityCodes', 'hiddenQuickSearchInputKeyword',
                    'hiddenQuickSearchInputPriceMin', 'hiddenQuickSearchInputPriceMax', 'hiddenQuickSearchInputMakerCodes',
                    'hiddenQuickSearchInputBodytypeCode', 'hiddenQuickSearchInputEquips', 'hiddenQuickSearchInputColorCodes',
                    'hiddenQuickSearchInputBodyTypeDetailNames', 'hiddenQuickSearchInputEngineType', 'hiddenQuickSearchInputCarEquipCodes',
                    'hiddenQuickSearchInputCampingCar', 'hiddenQuickSearchInputCommercialCars', 'hiddenQuickSearchInputTransmissionTypes']
  static values = { suggestionsUrl: String, ajaxGetPrefByNameUrl: String }

  connect() {
    let self = this
    const suggestionsUrl = new URL(this.suggestionsUrlValue)
    const commontAttr = {
      theme: 'classic',
      width: '100%',
      multiple: true,
      language: {
        noResults: function () { return ''; },
        maximumSelected: function () { return ''; }
      },
      minimumResultsForSearch: Infinity,
      placeholder: '',
    }

    $('.suggest-keyword').select2({
      ajax: {
        url: suggestionsUrl,
        dataType: 'json',
        data: function (params) {
          return Object.assign({}, { keyword: params.term, suggested: $('.suggest-keyword').select2('val'), column_names: ['keyword', 'prefecture_name'] });
        },
        processResults: function (data) {
          return {
            results: data.map(item => {
              return { id: item.suggest_field, text: item.suggest_field };
            })
          };
        },
        cache: false
      },
      tags: true,
      createTag: function (params) {
        var term = $.trim(params.term);
        if ($('.suggest-keyword').val().includes(term)) {
          return null;
        } else {
          return {
            id: term,
            text: term
          }
        }
      },
      ...commontAttr,
    });

    $('.suggest-pref-city').select2({
      ajax: {
        url: suggestionsUrl,
        dataType: 'json',
        data: function (params) {
          return {
            keyword: params.term,
            column_names: ['prefecture_name', 'pref_city_name']
          };
        },
        processResults: function (data) {
          return {
            results: data.map(item => {
              return { id: item.suggest_field, text: item.suggest_field, type: item.column_name };
            })
          };
        },
        cache: false
      },
      maximumSelectionLength: 2,
      ...commontAttr,
    });

    $(document.body).on("change", ".suggest-keyword", function(e) {
      let keywords = [];
      $(".suggest-keyword").select2("val").forEach((k) => {
        keywords.push(k.split(/[\ ,、]+/))
      });
      if(keywords.length > 0) {
        self.hiddenQuickSearchInputKeywordTarget.value = keywords.join(',')
      }
    });

    $('.suggest-pref-city').on('select2:select', function (e) {
      let prefCity = e.params.data.text.split(' - ');

      if (prefCity.length == 1) {
        var $newPref = $("<option selected='selected' data-type='pref'></option>").val(prefCity[0]).text(prefCity[0]);
        var $newCity = '';
        var data = { prefecture_name: prefCity[0], pref_city_name: '' };
      } else if (prefCity.length == 2) {
        var $newPref = $("<option selected='selected' data-type='pref'></option>").val(prefCity[0]).text(prefCity[0]);
        var $newCity = $("<option selected='selected' data-type='city'></option>").val(prefCity[1]).text(prefCity[1]);
        var data  = { prefecture_name: prefCity[0], pref_city_name: prefCity[1] };
      }

      $('.suggest-pref-city').empty()
      self.hiddenQuickSearchInputPrefectureCodesTarget.value = ''
      self.hiddenQuickSearchInputCityCodesTarget.value = ''
      $(".suggest-pref-city").append($newPref).append($newCity).trigger('change')

      ajax({
        url: '/home/ajax_get_pref_city_by_name',
        type: 'GET',
        dataType: 'jsonp',
        data: new URLSearchParams(data).toString(),
        success: function (response) {
          if(response['prefecture_code']) {
            var currentPrefs = self.hiddenQuickSearchInputPrefectureCodesTarget.value.split(',');
            currentPrefs.push(response['prefecture_code']);
            self.hiddenQuickSearchInputPrefectureCodesTarget.value = currentPrefs.filter(e => e != '').join(',');
          }
          if(response['city_code']) {
            self.hiddenQuickSearchInputCityCodesTarget.value = `${response['prefecture_code']}-${response['city_code']}`
          } else {
            self.hiddenQuickSearchInputCityCodesTarget.value = ''
          }
        }
      })
    });

    $('.suggest-pref-city').on('select2:unselect', function (e) {
      var targetType = e.params.data.element.getAttribute('data-type');
      if (targetType == 'city') {
        self.hiddenQuickSearchInputCityCodesTarget.value = '';
      } else if (targetType == 'pref') {
        var data = { prefecture_name: e.params.data.text, pref_city_name: '' };
        var currentPrefs = self.hiddenQuickSearchInputPrefectureCodesTarget.value.split(',');

        ajax({
          url: '/home/ajax_get_pref_city_by_name',
          type: 'GET',
          dataType: 'jsonp',
          data: new URLSearchParams(data).toString(),
          success: function (response) {
            if(response['prefecture_code']) {
              self.hiddenQuickSearchInputPrefectureCodesTarget.value = currentPrefs.filter(item => item !== response['prefecture_code']).join(',')
            }
          }
        })
      } else if (targetType == 'area') {
        var currentPrefs = self.hiddenQuickSearchInputPrefectureCodesTarget.value.split(',');
        var ids = e.params.data.element.getAttribute('data-ids');
        $(".suggest-pref-city option[value='" + e.params.data.text + "']").remove();

        self.hiddenQuickSearchInputPrefectureCodesTarget.value = currentPrefs.filter(item => !ids.includes(item)).join(',')
      }
    });

    $('#select-trigger-menu-prefecture').on('click', function (e) {
      const classModal = 'prefecture-options'
      let data = { class_modal: classModal }
      e.target.parentElement.getElementsByTagName('textarea')[0].style.caretColor = 'transparent';

      ajax({
        url: '/ajax_load_menu',
        type: 'GET',
        dataType: 'jsonp',
        data: new URLSearchParams(data).toString(),
        success: function (response) {
          $('.list-modal__container').html($.parseHTML(response.html))
          const modalTarget = document.querySelector('.cks-modal.prefecture-options')

          modalTarget.setAttribute('data-action-from', 'quick-search')
          modalTarget.classList.add('cks-modal--open')
          $(modalTarget).find('.chip-counter').hide()

          const componentActionTarget = document.getElementById('action-for-prefecture-options')
          if (componentActionTarget) {
            componentActionTarget.classList.add('menu-search-drawer__footer--open')
          }
          document.querySelector('.header-top-for-modal').classList.add('open')
          e.target.parentElement.getElementsByTagName('textarea')[0].style.caretColor = 'black';
          document.body.style.overflow = 'hidden'
          self.checkboxPref();
          self.checkboxAreas();
        }
      })
    });
  }

  checkboxPref() {
    var checkboxes = this.menuSearchController.cbPrefectureItemTargets;
    var prefectureCodes = this.hiddenQuickSearchInputPrefectureCodesTarget.value.split(',');
    checkboxes.forEach((cb) => {
      if (prefectureCodes.includes(cb.value)){
        cb.checked = true;
      } else {
        cb.checked = false;
      }
    });
  }

  checkboxAreas() {
    var checkboxes = this.menuSearchController.cbPrefectureAllInRangeTargets;
    var areas = [];

    $(".suggest-pref-city > option").each(function() {
      if (this.getAttribute('data-type') == 'area') {
        areas.push(this.value)
      }
    });
    checkboxes.forEach((cb) => {
      if (areas.includes(cb.getAttribute('data-area-name-check-all'))){
        cb.checked = true;
      } else {
        cb.checked = false;
      }
    });
  }

  selectPrefCity(prefCity) {
    let self = this;
    if (prefCity.length > 0) {
      if(prefCity.length > 1) {
        $('.suggest-pref-city').empty()
        var $newPref = $("<option selected='selected' data-type='aa'></option>").val(prefCity[0]).text(prefCity[0])
        var $newCity = $("<option selected='selected' data-type='bb'></option>").val(prefCity[1]).text(prefCity[1])
        $(".suggest-pref-city").append($newPref).append($newCity).trigger('change')
      }
      let data  = { prefecture_name: prefCity[0], pref_city_name: ((prefCity[1] === undefined) ? "" : prefCity[1]) }
      ajax({
        url: '/home/ajax_get_pref_city_by_name',
        type: 'GET',
        dataType: 'jsonp',
        data: new URLSearchParams(data).toString(),
        success: function (response) {
          if(response['prefecture_code']) {
            self.hiddenQuickSearchInputPrefectureCodesTarget.value = response['prefecture_code']
          }
          if(response['city_code']) {
            self.hiddenQuickSearchInputCityCodesTarget.value = `${response['prefecture_code']}-${response['city_code']}`
          } else {
            self.hiddenQuickSearchInputCityCodesTarget.value = ''
          }
        }
      })
    }
  }

  get menuSearchController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById('menu-search'), "menu-search")
  }
}
