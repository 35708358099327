import { Controller } from 'stimulus'
import { ajax } from '@rails/ujs'

export default class extends Controller {
  toggleFavorite(event) {
    const currentItem = event.currentTarget;
    let data  = { global_key: currentItem.getAttribute('data-global-key') };
    let favorite = (currentItem.getAttribute('data-favorite') === 'true');
    let url = (favorite ? '/cars/ajax_delete_favorite' : '/cars/ajax_add_favorite');

    ajax({
      url: url,
      type: 'POST',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        if (response.status) {
          let class_name = (favorite ? 'favorite_border' : 'favorite');

          currentItem.setAttribute('data-favorite', !favorite);
          currentItem.innerHTML = class_name;
          currentItem.classList.toggle('favorite-on');
        } else {
          let class_name = (favorite ? 'favorite' : 'favorite_border');

          currentItem.setAttribute('data-favorite', favorite);
          currentItem.innerHTML = class_name;
          currentItem.classList.toggle('favorite-on');

          alert(response.message);
        }
      }
    })
  }
}
