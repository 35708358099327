import { Controller } from 'stimulus'
import $ from 'jquery'
import { ajax } from '@rails/ujs'

export default class extends Controller {
  display() {
    let self = this;

    ajax({
      url: '/ajax_load_recent_history_search_recommend',
      type: 'GET',
      dataType: 'jsonp',
      beforeSend: function(){
        return true;
      },
      success: function (response) {
        $('.recent-history-search-recommend').html($.parseHTML(response.html))
      },
      error: function () {
      }
    })
  }
}
