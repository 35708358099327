import Cookies from 'js-cookie';
const $ = require('jquery')

export const showPopup = (popupContainerId, overlayId, btnCloseId, btnStayHereId = null) => {
  let currentOffsetTop = 0
  const BREAK_POINT    = 649
  const popupContainer = document.getElementById(popupContainerId)
  if (popupContainer === null) return;

  const overlay       = document.getElementById(overlayId)
  const btnClose      = document.getElementById(btnCloseId)
  const btnStayHere   = document.getElementById(btnStayHereId)
  const mainContainer = document.querySelector('.main-container')

  window.history.pushState({}, '', window.location.href)
  window.addEventListener('popstate', function(event) {
    if (window.location.hash !== '') return event.preventDefault()

    if (popupContainerId == 'popup_prevent_leaving_inquiry_page') {
      $('.main-container').stop()
      Cookies.set('hidePopupPreventLeavingInquiryPage', 'true', { expires: 90 })
    }

    currentOffsetTop = Math.max(window.pageYOffset, mainContainer.scrollTop)
    overlay.classList.remove('d-none')
    popupContainer.classList.remove('d-none')
    mainContainer.style.setProperty('overflow-y', 'hidden', 'important')

    if (window.innerWidth < BREAK_POINT) {
      mainContainer.scrollTo({ top: currentOffsetTop })
    } else {
      let topOffset = ( mainContainer.scrollTop + mainContainer.offsetHeight / 2)
      popupContainer.style.setProperty('top', `${topOffset}px`, 'important')
    }
  })

  overlay.addEventListener('click', () => { actionGoBack(mainContainer, popupContainer, overlay) })
  btnClose.addEventListener('click', () => { actionGoBack(mainContainer, popupContainer, overlay) })
  if (btnStayHere !== null) {
    btnStayHere.addEventListener('click', () => { actionGoBack(mainContainer, popupContainer, overlay) })
  }

  const actionGoBack = (mainContainer, popupContainer, overlay) => {
    popupContainer.classList.add('d-none')
    overlay.classList.add('d-none')
    if (window.innerWidth > BREAK_POINT) {
      mainContainer.style.setProperty('overflow-y', 'auto', 'important')
    } else {
      mainContainer.style.setProperty('overflow-y', 'visible', 'important')
      window.scrollTo({ top: currentOffsetTop })
    }
  }
}
