import breadcrumbsControllable from '../components/breadcrumbs'

const searchBreadcrumbsControllable = () => {
  let total = document.querySelector('[data-total]');
  if (!total) return;

  let currentItems = document.querySelectorAll('.item').length;
  let searchBreadcrumbs = document.querySelector('[data-search-breadcrumbs]');
  if (currentItems == parseInt(total.dataset.total) && searchBreadcrumbs != null && searchBreadcrumbs.style.display == '') {
    searchBreadcrumbs.style.display = 'block';
    breadcrumbsControllable();
  }
}

export default searchBreadcrumbsControllable;
