import { Controller } from 'stimulus'
import {ajax} from '@rails/ujs'
import { updateFavoriteBadge } from '../components/favorite_badge.js'

export default class extends Controller {
  static targets = ['favoriteButton']

  favorite(event) {
    let _this = this
    const currentItem = event.currentTarget
    let data  = { global_key: currentItem.getAttribute('data-global-key') }

    ajax({
      url: `/cars/ajax_add_favorite`,
      type: 'POST',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        if (response.status) {
          currentItem.setAttribute('data-action', 'recent-view#unFavorite')
          currentItem.classList.add('mdc-icon-button--on')
          updateFavoriteBadge(response.favorite_count)
        }
        else {
          currentItem.setAttribute('data-action', 'recent-view#favorite')
          currentItem.classList.remove('mdc-icon-button--on')
          alert(response.message)
        }
      }
    })
  }

  unFavorite(event) {
    let _this = this
    const currentItem = event.currentTarget
    let data  = { global_key: currentItem.getAttribute('data-global-key') }

    ajax({
      url: `/cars/ajax_delete_favorite`,
      type: 'POST',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        currentItem.setAttribute('data-action', 'recent-view#favorite')
        currentItem.classList.remove('mdc-icon-button--on')
        updateFavoriteBadge(response.favorite_count)
      }
    })
  }
}
