import { Controller } from "stimulus"

export default class extends Controller {
  collapse(event) {
  	let title = event.currentTarget;
    let panel = title.nextElementSibling;

    title.classList.toggle('active');

    if (panel.style.display === 'block') {
      title.getElementsByTagName('i')[0].innerHTML = 'keyboard_arrow_right'
      panel.style.display = 'none';
    } else {
      title.getElementsByTagName('i')[0].innerHTML = 'keyboard_arrow_down'
      panel.style.display = 'block';
    }
  }
}
