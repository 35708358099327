export const updateFavoriteBadge = (amount) => {
  const favorite_car_counter = document.getElementById('favorite_car_counter')

  favorite_car_counter.textContent = amount

  if (amount == 0)
    favorite_car_counter.parentElement.classList.remove('has__items')
  else
    favorite_car_counter.parentElement.classList.add('has__items')
}
