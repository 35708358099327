import { Controller } from 'stimulus'
import $ from 'jquery'
import { ajax } from '@rails/ujs'

export default class extends Controller {
  display() {
    let self = this;

    ajax({
      url: '/ajax_load_top_history_search',
      type: 'GET',
      dataType: 'jsonp',
      beforeSend: function(){
        return true;
      },
      success: function (response) {
        $('.top-history-search').html($.parseHTML(response.html))
      },
      error: function () {
      }
    })
  }
}
