import { Controller } from 'stimulus'
import {ajax} from '@rails/ujs'
import $ from 'jquery'
import LazyLoad from 'vanilla-lazyload';

require('select2/dist/css/select2')

import Select2 from "select2"
import Accordion from 'accordion-js';
import 'accordion-js/dist/accordion.min.css';

export default class extends Controller {
  static values = { suggestionsUrl: String }

  static targets = ['cbPriceMin', 'cbPriceMax', 'carModelHeadItem', 'cbCityItem',
                    'menuItemCity', 'cbMaker', 'cbCarModelItem', 'cbGradeItem', 'bodytypeItem',
                    'cbPrefectureItem', 'cbEquipItem', 'menuItemCarModel', 'menuItemGrade',
                    'hiddenInputPriceMin', 'hiddenInputPriceMax', 'hiddenInputMakerCodes',
                    'hiddenInputCarModelCodes', 'hiddenInputBodytypeCode', 'hiddenInputGradeCodes', 'hiddenInputFmcCodes',
                    'hiddenInputPrefectureCodes', 'hiddenInputCityCodes', 'hiddenInputEquips', 'hiddenInputKeyword',
                    'displayPriceMinSelected', 'displayPriceMaxSelected', 'displayMakerSelected',
                    'displayCarModelSelected', 'displayBodytypeSelected', 'displayGradeSelected',
                    'displayPrefectureSelected', 'displayCitySelected', 'displayEquipSelected',
                    'displayMakerNoData', 'displayCarModelNoData', 'displayBodytypeNoData',
                    'displayGradeNoData', 'displayPrefectureNoData', 'displayCityNoData', 'displayEquipNoData',
                    'carModelLoading', 'gradeLoading', 'cityLoading', 'cbPrefectureAllInRange', 'makerOptionButtonSubmit',
                    'cbColor', 'displayColorNoData', 'displayColorSelected', 'hiddenInputColorCodes', 'cbSpecification',
                    'displaySpecificationNoData', 'displaySpecificationSelected', 'hiddenInputCarEquipCodes',
                    'hiddenInputBodyTypeDetailNames', 'hiddenInputEngineType', 'hiddenInputCarEquipCodes', 'hiddenInputCampingCar',
                    'hiddenInputCommercialCars', 'hiddenInputTransmissionTypes']

  connect() {
    let self = this
    const suggestionsUrl = new URL(this.suggestionsUrlValue)
    const commontAttr = {
      theme: 'classic',
      width: '100%',
      multiple: true,
      placeholder: '',
      language: {
        noResults: function () { return ''; },
        maximumSelected: function () { return ''; }
      },
      minimumResultsForSearch: Infinity,
    }

    $('.menu-search-suggest-keyword').select2({
      ajax: {
        url: suggestionsUrl,
        dataType: 'json',
        data: function (params) {
          return Object.assign({}, { keyword: params.term, suggested: $('.menu-search-suggest-keyword').select2('val'), column_names: ['keyword', 'prefecture_name'] });
        },
        processResults: function (data) {
          return {
            results: data.map(item => {
              return { id: item.suggest_field, text: item.suggest_field };
            })
          };
        },
        cache: false
      },
      tags: true,
      createTag: function (params) {
        var term = $.trim(params.term);
        if ($('.menu-search-suggest-keyword').val().includes(term)) {
          return null;
        } else {
          return {
            id: term,
            text: term
          }
        }
      },
      ...commontAttr
    });

    $(document.body).on("change", ".menu-search-suggest-keyword", function(e) {
      let keywords = [];
      $(".menu-search-suggest-keyword").select2("val").forEach((k) => {
        keywords.push(k.split(/[\ ,、]+/))
      });
      if(keywords.length > 0) {
        self.hiddenInputKeywordTarget.value = keywords.join(',')
      }
    });

    var isSubmitting = false;
  }

  reloadMenuSearch(currentUrl) {
    let self = this;
    let data = { current_url: decodeURIComponent(currentUrl) }

    ajax({
      url: '/ajax_reload_menu',
      type: 'GET',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        if (!response) {
          return
        }
        if (response.price_min) {
          searchParams.priceMin = response.price_min.value
          self.hiddenInputPriceMinTarget.value = response.price_min.value
          self.displayPriceMinSelectedTarget.innerText = `${response.price_min.name} ~`
          self.displayPriceMinSelectedTarget.classList.add('selected')
        }

        if (response.price_max) {
          searchParams.priceMax = response.price_max.value
          self.hiddenInputPriceMaxTarget.value = response.price_max.value
          self.displayPriceMaxSelectedTarget.innerText = `${response.price_max.name}`
          self.displayPriceMaxSelectedTarget.classList.add('selected')
        }

        if (response.maker) {
          searchParams.makerCodes = response.maker.value
          self.hiddenInputMakerCodesTarget.value = response.maker.value
          self.displayMakerSelectedTarget.innerText = response.maker.name
          self.displayMakerNoDataTarget.innerText = ''
          self.increateHeightItem(self.displayMakerNoDataTarget)

          self.menuItemCarModelTarget.parentElement.classList.remove('inactive')
          self.menuItemCarModelTarget.setAttribute('data-action', 'click->menu-search#openModal')
        }
        if (response.car_model) {
          self.menuItemCarModelTarget.parentElement.classList.remove('inactive')
          self.menuItemCarModelTarget.setAttribute('data-action', 'click->menu-search#openModal')

          searchParams.carModelCodes = response.car_model.value
          self.hiddenInputCarModelCodesTarget.value = response.car_model.value
          self.displayCarModelSelectedTarget.innerText = response.car_model.name
          self.displayCarModelNoDataTarget.innerText = ''
          self.increateHeightItem(self.displayCarModelNoDataTarget)
          self.menuItemGradeTarget.parentElement.classList.remove('inactive')
          self.menuItemGradeTarget.setAttribute('data-action', 'click->menu-search#openModal')
        } else if (!response.maker) {
          self.inactiveMenuItemCarModel()
        }

        if (response.body) {
          if (response.body.name === undefined) return;

          searchParams.bodytypeCode = response.body.value
          self.hiddenInputBodytypeCodeTarget.value = response.body.value
          self.displayBodytypeSelectedTarget.innerText = response.body.name
          self.displayBodytypeNoDataTarget.innerText = ''
          self.increateHeightItem(self.displayBodytypeNoDataTarget)
        }

        if (response.grade) {
          self.menuItemGradeTarget.parentElement.classList.remove('inactive')
          self.menuItemGradeTarget.setAttribute('data-action', 'click->menu-search#openModal')

          searchParams.gradeCodes = response.grade.value
          self.hiddenInputGradeCodesTarget.value = response.grade.value
          self.displayGradeSelectedTarget.innerText = [self.displayGradeSelectedTarget.innerText, response.grade.name].filter(item => item).join('、')
          self.displayGradeNoDataTarget.innerText = ''
          self.increateHeightItem(self.displayGradeNoDataTarget)
        } else if (!response.car_model) {
          self.inactiveMenuItemGrade()
        }

        if (response.fmc_model) {
          self.menuItemGradeTarget.parentElement.classList.remove('inactive')
          self.menuItemGradeTarget.setAttribute('data-action', 'click->menu-search#openModal')

          searchParams.fmcCodes = response.fmc_model.value
          self.hiddenInputFmcCodesTarget.value = response.fmc_model.value
          self.displayGradeSelectedTarget.innerText = [self.displayGradeSelectedTarget.innerText, response.fmc_model.name].filter(item => item).join('、')
          self.displayGradeNoDataTarget.innerText = ''
          self.increateHeightItem(self.displayGradeNoDataTarget)
        } else if (!response.car_model) {
          self.inactiveMenuItemGrade()
        }

        if (response.prefecture) {
          searchParams.prefectureCodes = response.prefecture.value
          self.hiddenInputPrefectureCodesTarget.value = response.prefecture.value
          self.displayPrefectureSelectedTarget.innerText = response.prefecture.name
          self.displayPrefectureNoDataTarget.innerText = ''
          self.increateHeightItem(self.displayPrefectureNoDataTarget)

          self.menuItemCityTarget.parentElement.classList.remove('inactive')
          self.menuItemCityTarget.setAttribute('data-action', 'click->menu-search#openModal')
        }

        if (response.city) {
          self.menuItemCityTarget.parentElement.classList.remove('inactive')
          self.menuItemCityTarget.setAttribute('data-action', 'click->menu-search#openModal')

          searchParams.cityCodes = response.city.value
          self.hiddenInputCityCodesTarget.value = response.city.value
          self.displayCitySelectedTarget.innerText = response.city.name
          self.displayCityNoDataTarget.innerText = ''
          self.increateHeightItem(self.displayCityNoDataTarget)
        } else if (!response.prefecture) {
          self.inactiveMenuItemCity()
        }

        if (response.options) {
          searchParams.equips = response.options.value
          self.displayEquipNoDataTarget.innerText = ''
          self.hiddenInputEquipsTarget.value = response.options.value
          self.displayEquipSelectedTarget.innerText = response.options.name
          self.increateHeightItem(self.displayEquipNoDataTarget)
        }

        if (response.color) {
          searchParams.colorCodes = response.color.value
          self.displayColorNoDataTarget.innerText = ''
          self.hiddenInputColorCodesTarget.value = response.color.value
          self.displayColorSelectedTarget.innerText = response.color.name
          self.increateHeightItem(self.displayColorNoDataTarget)
        }

        if (response.body_type_detail_name) {
          searchParams.bodyTypeDetailNames = response.body_type_detail_name.value
          self.displaySpecificationNoDataTarget.innerText = ''
          self.hiddenInputBodyTypeDetailNamesTarget.value = response.body_type_detail_name.value
          self.displaySpecificationSelectedTarget.innerText = response.body_type_detail_name.name
          self.increateHeightItem(self.displaySpecificationNoDataTarget)
        }

        if (response.engine_type) {
          searchParams.engineType = response.engine_type.value
          self.displaySpecificationNoDataTarget.innerText = ''
          self.hiddenInputEngineTypeTarget.value = response.engine_type.value
          self.displaySpecificationSelectedTarget.innerText = self.appendTextDisplaySpecification(response.engine_type.name)
          self.increateHeightItem(self.displaySpecificationNoDataTarget)
        }

        if (response.car_equip) {
          searchParams.carEquipCodes = response.car_equip.value
          self.displaySpecificationNoDataTarget.innerText = ''
          self.hiddenInputCarEquipCodesTarget.value = response.car_equip.value
          self.displaySpecificationSelectedTarget.innerText = self.appendTextDisplaySpecification(response.car_equip.name)
          self.increateHeightItem(self.displaySpecificationNoDataTarget)
        }

        if (response.camping_car) {
          searchParams.campingCar = response.camping_car.value
          self.displaySpecificationNoDataTarget.innerText = ''
          self.hiddenInputCampingCarTarget.value = response.camping_car.value
          self.displaySpecificationSelectedTarget.innerText = self.appendTextDisplaySpecification(response.camping_car.name)
          self.increateHeightItem(self.displaySpecificationNoDataTarget)
        }

        if (response.commercial_car) {
          searchParams.commercialCars = response.commercial_car.value
          self.displaySpecificationNoDataTarget.innerText = ''
          self.hiddenInputCommercialCarsTarget.value = response.commercial_car.value
          self.displaySpecificationSelectedTarget.innerText = self.appendTextDisplaySpecification(response.commercial_car.name)
          self.increateHeightItem(self.displaySpecificationNoDataTarget)
        }

        if (response.transmission_type) {
          searchParams.transmissionTypes = response.transmission_type.value
          self.displayEquipNoDataTarget.innerText = ''
          self.hiddenInputTransmissionTypesTarget.value = response.transmission_type.value
          self.displayEquipSelectedTarget.innerText = self.appendTextDisplayEquip(response.transmission_type.name)
          self.increateHeightItem(self.displayEquipNoDataTarget)
        }
      }
    })
  }

  temporaryConcealment(e) {
    e.style.pointerEvents = 'none';
    e.style.color = '#ccc';
  }

  disabledTemporaryConcealment(e) {
    e.style.pointerEvents = 'auto';
    e.style.color = '#333';
  }

  openModal(el) {
    if(this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    let self = this;
    const classModal = el.target.dataset.modalTarget
    let data = { class_modal: classModal, search_params: searchParams.makeParams() }

    ajax({
      url: '/ajax_load_menu',
      type: 'GET',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      beforeSend: function(){
        switch(classModal) {
          case 'city-options':
            self.temporaryConcealment(self.menuItemCityTarget.parentElement)
            self.cityLoadingTarget.classList.add('show')
            break
          case 'car-modal-options':
            self.temporaryConcealment(self.menuItemCarModelTarget.parentElement)
            self.carModelLoadingTarget.classList.add('show')
            break
          case 'grade-options':
            self.temporaryConcealment(self.menuItemGradeTarget.parentElement)
            self.gradeLoadingTarget.classList.add('show')
            break
        }
        return true;
      },
      success: function (response) {
        $('.list-modal__container').html($.parseHTML(response.html))
        const modalTarget = document.querySelector(`.${classModal}`)
        if (modalTarget === null)
          return ajaxOpenModalError()

        switch(classModal) {
          case 'maker-options':
            if (el.target.dataset.modalPosition == 'top') {
              self.makerOptionButtonSubmitTarget.dataset.action = 'click->menu-search#setMakerTopPage'
            }
            break
          case 'car-modal-options':
            const lazyLoadOptions = { use_native: true }
            const pageLazyLoad = new LazyLoad(lazyLoadOptions)
            setTimeout(() => {
              self.carModelLoadingTarget.classList.remove('show')
              self.disabledTemporaryConcealment(self.menuItemCarModelTarget.parentElement)
            }, 600);
            break
          case 'grade-options':
            if (response.grade_size > 0) {
              const lazyLoadOptions = { use_native: true }
              const pageLazyLoad = new LazyLoad(lazyLoadOptions);
              self.disabledTemporaryConcealment(self.menuItemGradeTarget.parentElement)
              self.menuItemGradeTarget.setAttribute('data-action', 'click->menu-search#openModal')
              self.gradeLoadingTarget.classList.remove('show')
              if (document.querySelector('.accordion-container-of-grade')) {
                new Accordion('.accordion-container-of-grade', { duration: 300 });
              }
              if (searchParams.gradeCodes.length > 0) {
                self.cbGradeItemTargets.forEach(e => {
                  e.checked = false
                  if (searchParams.gradeCodes.includes(e.value)) { e.checked = true }
                })
              }
              if (searchParams.fmcCodes.length > 0) {
                $(function () {
                  const acHeders = document.querySelectorAll('.grade-option__container ul li.ac-header input[type=checkbox]')
                  acHeders.forEach(el => {
                    if (searchParams.fmcCodes.includes(el.value.split('=')[1])) { el.checked = true }
                  })

                  const acHeaderChecked = document.querySelectorAll('.grade-option__container ul li.ac-header input:checked')
                  acHeaderChecked.forEach(el => {
                    el.closest('.ac-header').nextSibling.querySelectorAll('ul li').forEach(p => p.classList.add('inactive'))
                    el.closest('.ac-header').nextSibling.querySelectorAll('ul li input[type=checkbox]').forEach(el => {
                      el.checked  = false
                      el.disabled = true
                    })
                  })
                });
              }
            } else {
              self.inactiveMenuItemGrade()
            }
            break
          case 'prefecture-options':
            modalTarget.setAttribute('data-action-from', '')
            $(modalTarget).find('.chip-counter').show()
            break
          case 'city-options':
            setTimeout(() => {
              self.cityLoadingTarget.classList.remove('show')
              self.disabledTemporaryConcealment(self.menuItemCityTarget.parentElement)
            }, 600);
            break
          case 'other-options':
            if (document.querySelector('.accordion-container')) {
              new Accordion('.accordion-container', { duration: 300 });
            }
            break
        }
        modalTarget.classList.add('cks-modal--open')
        const idComponentAction = el.target.dataset.idComponentAction
        const componentActionTarget = document.getElementById(idComponentAction)
        if (componentActionTarget) {
          componentActionTarget.classList.add('menu-search-drawer__footer--open')
        }
        self.headerTop().classList.add('open')
        self.keepSelectedValue(`.cks-modal.${classModal}`)
        self.isSubmitting = false;
      },
      error: ajaxOpenModalError
    });

    function ajaxOpenModalError(response) {
      switch(classModal) {
        case 'city-options':
          self.inactiveMenuItemCity()
          break
        case 'car-modal-options':
          self.inactiveMenuItemCarModel()
          break
        case 'grade-options':
          self.inactiveMenuItemGrade()
          break
      }
      self.isSubmitting = false;
    };

  }

  closeModal(el) {
    el.target.parentElement.parentElement.classList.remove('menu-search-drawer__footer--open')
    el.target.parentElement.parentElement.parentElement.classList.remove('cks-modal--open')
    this.headerTop().classList.remove('open')
  }

  closeModalBodyType() {
    document.querySelector('.cks-modal.body-type-options.cks-modal--open').classList.remove('cks-modal--open')
    this.headerTop().classList.remove('open')
  }

  clearChecbox(el) {
    this.keepSelectedValue(el.target.dataset.clearComponentTarget)
    this.closeModal(el)
    document.body.style.overflow = 'auto'
  }

  keepSelectedValue(classModalTarget) {
    let selectedValues
    const listCheckbox = $(classModalTarget).find("input[type='checkbox']")

    switch(classModalTarget) {
      case '.cks-modal.maker-options':
        selectedValues = searchParams.makerCodes
        break;
      case '.cks-modal.car-modal-options':
        selectedValues = searchParams.carModelCodes
        listCheckbox.each(function () {
          this.checked = selectedValues.includes(this.getAttribute('data-maker-shashu-compiled'))
        });
        break;
      case '.cks-modal.grade-options':
        selectedValues = searchParams.gradeCodes
        break;
      case '.cks-modal.prefecture-options':
        selectedValues = searchParams.prefectureCodes
        break;
      case '.cks-modal.city-options':
        selectedValues = searchParams.cityCodes
        break;
      case '.cks-modal.other-options':
        selectedValues = searchParams.equips.concat(searchParams.transmissionTypes)
        let currentItem
        listCheckbox.each(function () {
          this.checked = selectedValues.includes(this.value)
          if (this.checked) {
            currentItem = this
          }
        });

        if (currentItem) {
          currentItem = currentItem.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
          currentItem.classList.add('is-active')
          currentItem.firstChild.setAttribute('aria-expanded', true)
          currentItem.firstChild.nextSibling.setAttribute('style',"transition-duration: 300ms; height: auto;")
        }
        break;
      case '.cks-modal.color-options':
        selectedValues = searchParams.colorCodes
        break;

      case '.cks-modal.specification-options':
        selectedValues = searchParams.bodyTypeDetailNames.concat(
          searchParams.engineType, searchParams.carEquipCodes, searchParams.campingCar, searchParams.commercialCars)
        break;
    }

    if (classModalTarget !== '.cks-modal.car-modal-options' && classModalTarget !== '.cks-modal.other-options') {
      listCheckbox.each(function () {
        this.checked = selectedValues.includes(this.value)
      });

      if (classModalTarget === '.cks-modal.prefecture-options') {
        const wraper         = document.querySelector('.cks-modal.prefecture-options')
        let prefCheckedLists = wraper.querySelectorAll('li input[type=checkbox]:checked')
        if (prefCheckedLists.length === 0) return;

        prefCheckedLists.forEach(cb => this.verifyAllItemChecked(cb))
      }
    }
  }

  headerTop() {
    return document.querySelector('.header-top-for-modal')
  }

  goToByScroll(el) {
    var scrolling = false;
    var scrollable = document.querySelectorAll('.scrollable');
    var i;
    for (i = 0; i < scrollable.length; i++) {
      if (scrollable[i].getAttribute('data-scrolling') == 'true') {
        scrolling = true;
      }
    }
    if (!scrolling) {
      el.target.setAttribute('data-scrolling', 'true');
      let idAreaCode = ''
      let notLiTag   = (el.target.tagName == 'SPAN' || el.target.tagName == 'I')
      if (notLiTag)
        idAreaCode = el.target.closest('li').dataset.areaCode
      else
        idAreaCode = el.target.dataset.areaCode

      const target = document.querySelector(`a#${idAreaCode}`)
      $('.prefecture-options.cks-modal--open .prefecture-option__container').animate({scrollTop: target.offsetTop - 60}, 600);
      setTimeout(() => {
        el.target.setAttribute('data-scrolling', 'false');
      }, 1000)
    }
  }

  carModelGoToByScroll(el) {
    const id = el.target.dataset.carModelCode
    const target = document.querySelector(`a#${id}`)
    $('.car-modal-options.cks-modal--open .car-modal-option__container').animate({scrollTop: target.offsetTop - 60}, 600);
  }

  selectAllInRange(el) {
    const dataAttrRootTarget = el.target.dataset.itemRootTarget
    if(el.target.checked)
      $(`[data-item-parent-target='${dataAttrRootTarget}']`).prop('checked', true)
    else
      $(`[data-item-parent-target='${dataAttrRootTarget}']`).prop('checked', false)
  }

  verifyAllItemChecked(el) {
    const dataAttrParentTarget = el.target == undefined ? el.dataset.itemParentTarget : el.target.dataset.itemParentTarget
    const listItemSize = document.querySelectorAll(`[data-item-parent-target='${dataAttrParentTarget}']`).length
    const listItemCheckedSize = document.querySelectorAll(`[data-item-parent-target='${dataAttrParentTarget}']:checked`).length

    if (listItemSize === listItemCheckedSize)
      $(`[data-item-root-target='${dataAttrParentTarget}']`).prop('checked', true)
    else
      $(`[data-item-root-target='${dataAttrParentTarget}']`).prop('checked', false)
  }

  verifyCheckedItem(el) {
    const dataAttrParentTarget = el.target == undefined ? el.dataset.itemParentTarget : el.target.dataset.itemParentTarget
    const listItemSize = document.querySelectorAll(`[data-item-parent-target='${dataAttrParentTarget}'].active`).length
    const listItemCheckedSize = document.querySelectorAll(`[data-item-parent-target='${dataAttrParentTarget}'].active:checked`).length
    const allListSize = document.querySelectorAll(`[data-item-parent-target='${dataAttrParentTarget}']`).length

    if (listItemCheckedSize <= listItemSize)
      $(`[data-item-parent-target='${dataAttrParentTarget}']:not(.active)`).prop('checked', false)

    if (allListSize === listItemCheckedSize)
      $(`[data-item-root-target='${dataAttrParentTarget}']`).prop('checked', true)
    else
      $(`[data-item-root-target='${dataAttrParentTarget}']`).prop('checked', false)
  }

  alphabetCarModelActived(el) {
    this.carModelHeadItemTargets.forEach(p => p.parentElement.classList.remove('active'))
    el.target.parentElement.classList.add('active')
  }

  // Modal City Hanlder

  unlockModalCity(el) {
    document.body.style.overflow = 'auto'

    const prefChecked = this.cbPrefectureItemTargets.filter(e => e.checked)
    this.setPrefectureCodes(prefChecked)
    const classModal = 'prefecture-options'
    const modalTarget = document.querySelector(`.${classModal}`)

    if (modalTarget.getAttribute('data-action-from') == 'quick-search') {
      this.closeModal(el)
      return
    }

    this.removeValueCity()
    if (prefChecked.length == 0) {
      this.closeModal(el)
      this.inactiveMenuItemCity()
      this.removeOldModal('.cks-modal.city-options')
      return
    }
    this.menuItemCityTarget.parentElement.classList.remove('inactive')
    this.menuItemCityTarget.setAttribute('data-action', 'click->menu-search#openModal')
    this.closeModal(el)
  }

  inactiveMenuItemCity() {
    this.menuItemCityTarget.parentElement.classList.add('inactive')
    this.menuItemCityTarget.setAttribute('data-action', '')
    this.removeOldModal('.cks-modal.city-options')
  }

  // Modal Car Model Hanlder

  unlockModalCarModel(el) {
    const makerChecked = this.cbMakerTargets.filter(e => e.checked)
    this.setMakerCodes(makerChecked)
    this.removeValueCarModel()
    this.removeValueGrade()
    if (makerChecked.length == 0) {
      this.inactiveMenuItemCarModel()
      this.inactiveMenuItemGrade()
      this.removeOldModal('.cks-modal.car-modal-options')
      this.removeOldModal('.cks-modal.grade-options')
    } else {
      this.menuItemCarModelTarget.parentElement.classList.remove('inactive')
      this.menuItemCarModelTarget.setAttribute('data-action', 'click->menu-search#openModal')
      const carModelChecked = this.cbCarModelItemTargets.filter(e => e.checked)
      if (carModelChecked.length == 0) {
        this.inactiveMenuItemGrade()
      }
    }
    this.closeModal(el)
  }

  inactiveMenuItemCarModel() {
    this.menuItemCarModelTarget.parentElement.classList.add('inactive')
    this.menuItemCarModelTarget.setAttribute('data-action', '')
    this.removeOldModal('.cks-modal.car-modal-options')
  }

  // Modal Grade Hanlder

  unlockModalGrade(el) {
    const carModelChecked = this.cbCarModelItemTargets.filter(e => e.checked)
    this.setCarModelCodes(carModelChecked)
    this.removeValueGrade()

    if (carModelChecked.length == 0) {
      this.inactiveMenuItemGrade()
    } else {
      this.gradeLoadingTarget.classList.remove('show')
      this.disabledTemporaryConcealment(this.menuItemGradeTarget.parentElement)
      this.menuItemGradeTarget.parentElement.classList.remove('inactive')
      this.menuItemGradeTarget.setAttribute('data-action', 'click->menu-search#openModal')
    }
    this.closeModal(el)
  }

  inactiveMenuItemGrade() {
    this.menuItemGradeTarget.parentElement.classList.add('inactive')
    this.menuItemGradeTarget.setAttribute('data-action', '')
    this.removeOldModal('.cks-modal.grade-options')
  }

  selectAllGradeInRange(el) {
    const liTagLists = el.target.closest('.ac-header').nextSibling.querySelectorAll('ul li')
    const childs     = el.target.closest('.ac-header').nextSibling.querySelectorAll('input[type=checkbox]')

    if (childs.length == 0) return;

    if (el.target.checked) {
      liTagLists.forEach(el => { el.classList.add('inactive') })
      childs.forEach(el => { el.disabled = true })
      $(childs).prop('checked', false)
    }
    else {
      liTagLists.forEach(el => { el.classList.remove('inactive') })
      childs.forEach(el => { el.disabled = false })
      $(childs).prop('checked', false)
    }
  }

  verifyAllGradeItemChecked(el) {
    const currentParent     = el.target.closest('.ac-panel')
    let listItemSize        = currentParent.querySelectorAll('input[type=checkbox]').length
    let listItemCheckedSize = currentParent.querySelectorAll('input[type=checkbox]:checked').length
    $(function () {
      if (listItemSize === listItemCheckedSize){
        currentParent.previousSibling.querySelector('input[type=checkbox]').checked = true
        currentParent.querySelectorAll('li').forEach(el => { el.classList.add('inactive') })
        currentParent.querySelectorAll('input[type=checkbox]').forEach(el => {
          el.checked  = false
          el.disabled = true
        })
      } else {
        currentParent.previousSibling.querySelector('input[type=checkbox]').checked = false
      }
    });
  }

  // Shared
  removeOldModal(selector) {
    const container = document.querySelector(selector)
    if (container) container.remove()
  }

  // ============================= //
  // Handle display value in form  //
  // ============================= //
  setPriceMin(el) {
    const target = el.target.dataset
    if (target.priceValue !== '') {
      searchParams.priceMin = target.priceValue
      if (this.hasHiddenInputPriceMinTarget) this.hiddenInputPriceMinTarget.value = target.priceValue;
      this.displayPriceMinSelectedTarget.innerText = `${target.priceDisplay} ~`
      this.displayPriceMinSelectedTarget.classList.add('selected')
      this.validatePrice()
    } else {
      searchParams.priceMin = undefined
      if (this.hasHiddenInputPriceMinTarget) this.hiddenInputPriceMinTarget.value = '';
      this.displayPriceMinSelectedTarget.innerText = `Min ~`
      this.displayPriceMinSelectedTarget.classList.remove('selected')
    }
    this.closeModalPrice(el)
  }

  setPriceMax(el) {
    const target = el.target.dataset
    if (target.priceValue !== '') {
      searchParams.priceMax = target.priceValue
      if (this.hasHiddenInputPriceMaxTarget) this.hiddenInputPriceMaxTarget.value = target.priceValue;
      this.displayPriceMaxSelectedTarget.innerText = `${target.priceDisplay}`
      this.displayPriceMaxSelectedTarget.classList.add('selected')
      this.validatePrice()
    } else {
      searchParams.priceMax = undefined
      if (this.hasHiddenInputPriceMaxTarget) this.hiddenInputPriceMaxTarget.value = '';
      this.displayPriceMaxSelectedTarget.innerText = 'Max'
      this.displayPriceMaxSelectedTarget.classList.remove('selected')
    }
    this.closeModalPrice(el)
  }

  closeModalPrice(el) {
    el.target.parentElement.parentElement.parentElement.parentElement.classList.remove('cks-modal--open')
    this.headerTop().classList.remove('open')
  }

  validatePrice() {
    if (!searchParams.priceMin || !searchParams.priceMax) return;
    if (parseInt(searchParams.priceMin ?? 0) < parseInt(searchParams.priceMax ?? 0)) return;

    const currentPriceMin        = searchParams.priceMin
    const currentDisplayPriceMin = this.displayPriceMinSelectedTarget.textContent
    searchParams.priceMin = searchParams.priceMax

    if (this.hasHiddenInputPriceMinTarget) this.hiddenInputPriceMinTarget.value = searchParams.priceMax;
    this.displayPriceMinSelectedTarget.innerText = `${this.displayPriceMaxSelectedTarget.textContent} ~`

    searchParams.priceMax = currentPriceMin
    if (this.hasHiddenInputPriceMaxTarget) this.hiddenInputPriceMaxTarget.value = currentPriceMin;
    this.displayPriceMaxSelectedTarget.innerText = currentDisplayPriceMin.replace(' ~', '')
  }

  setMakerCodes(makerChecked) {
    if (makerChecked.length == 0) {
      searchParams.makerCodes = []
      if(this.hasHiddenInputMakerCodesTarget) this.hiddenInputMakerCodesTarget.value = '';
      this.displayMakerSelectedTarget.innerText = ''
      this.displayMakerNoDataTarget.innerText = '指定なし'
      this.resetHeightItem(this.displayMakerNoDataTarget)
    } else {
      const values = makerChecked.map(mk => mk.value)
      const text = makerChecked.map(mk => mk.parentElement.previousSibling.firstChild.innerText).join('、')
      searchParams.makerCodes = values
      if(this.hasHiddenInputMakerCodesTarget) this.hiddenInputMakerCodesTarget.value = values;
      this.displayMakerSelectedTarget.innerText = text
      this.displayMakerNoDataTarget.innerText = ''
      this.increateHeightItem(this.displayMakerNoDataTarget)
    }
  }

  setCarModelCodes(carModelChecked) {
    if (carModelChecked.length == 0) {
      this.removeValueCarModel()
    } else {
      const values = carModelChecked.map(cm => cm.dataset.makerShashuCompiled)
      const text = carModelChecked.map(mk => mk.parentElement.previousSibling.firstChild.innerText).join('、')
      searchParams.carModelCodes = values
      this.hiddenInputCarModelCodesTarget.value = values
      this.displayCarModelSelectedTarget.innerText = text
      this.displayCarModelNoDataTarget.innerText = ''
      this.increateHeightItem(this.displayCarModelNoDataTarget)
    }
  }

  removeValueCarModel() {
    searchParams.carModelCodes = []
    this.hiddenInputCarModelCodesTarget.value = ''
    this.displayCarModelSelectedTarget.innerText = ''
    this.displayCarModelNoDataTarget.innerText = '指定なし'
    this.resetHeightItem(this.displayCarModelNoDataTarget)
  }

  setBodytypeCode(el) {
    const bodytypeCode = el.target.dataset.bodytypeCode
    if (bodytypeCode !== '') {
      const text = el.target.nextSibling.getElementsByTagName('span')[0].textContent
      searchParams.bodytypeCode = bodytypeCode
      if(this.hasHiddenInputBodytypeCodeTarget) this.hiddenInputBodytypeCodeTarget.value = bodytypeCode;
      this.displayBodytypeSelectedTarget.innerText = text
      this.displayBodytypeNoDataTarget.innerText = ''
      this.increateHeightItem(this.displayBodytypeNoDataTarget)
    } else {
      searchParams.bodytypeCode = []
      if(this.hasHiddenInputBodytypeCodeTarget) this.hiddenInputBodytypeCodeTarget.value = '';
      this.displayBodytypeSelectedTarget.innerText = ''
      this.displayBodytypeNoDataTarget.innerText = '指定なし'
      this.resetHeightItem(this.displayBodytypeNoDataTarget)
    }
    this.closeModalBodyType()
  }

  setGradeCodes(el) {
    const targets = this.cbGradeItemTargets.filter(x => x.checked)
    if (targets.length == 0){
      this.removeValueGrade()
    } else {
      let fmcCodes = []
      const ids = targets.map(e => e.value)
      const text = targets.map(e => e.parentElement.nextElementSibling.querySelector('span').textContent)

      let preFcmCodes = ids.filter(el => el.includes('fmcCodes='))
      let gradeIds    = ids.filter(el => !el.includes('fmcCodes='))
      if (preFcmCodes) {
        fmcCodes = preFcmCodes.map(e => e.split('=')[1])
      }

      searchParams.fmcCodes = fmcCodes
      searchParams.gradeCodes = gradeIds
      this.hiddenInputFmcCodesTarget.value = fmcCodes
      this.hiddenInputGradeCodesTarget.value = gradeIds
      this.displayGradeSelectedTarget.innerText = text.join('、')
      this.displayGradeNoDataTarget.innerText = ''
      this.increateHeightItem(this.displayGradeNoDataTarget)
    }
    this.closeModal(el)
  }

  removeValueGrade() {
    searchParams.fmcCodes = []
    searchParams.gradeCodes = []
    this.hiddenInputFmcCodesTarget.value = ''
    this.hiddenInputGradeCodesTarget.value = ''
    this.displayGradeSelectedTarget.innerText = ''
    this.displayGradeNoDataTarget.innerText = '指定なし'
    this.resetHeightItem(this.displayGradeNoDataTarget)
  }

  setPrefectureCodes(prefChecked) {
    const ids          = []
    const text         = []
    const areaTypes    = []
    const areaIds      = []
    const areas        = ['hokkaido', 'kanto', 'hokushinetsu', 'tokai', 'kansai', 'chugoku', 'kyushu']
    const _prefChecked = prefChecked
    const classModal = 'prefecture-options'
    const modalTarget = document.querySelector(`.${classModal}`)

    if (prefChecked.length == 0) {
      if (modalTarget.getAttribute('data-action-from') == 'quick-search') {
        if(this.suggestionsSearchController) {
          this.suggestionsSearchController.hiddenQuickSearchInputPrefectureCodesTarget.value = ''
          this.suggestionsSearchController.hiddenQuickSearchInputCityCodesTarget.value = ''
          $('.suggest-pref-city').empty().trigger('change')
          $('.input-field-pref-city-container .cks-label-for-suggest').removeClass('active');
        }
      } else {
        searchParams.prefectureCodes = []
        this.hiddenInputPrefectureCodesTarget.value = ''

        this.displayPrefectureSelectedTarget.innerText = ''
        this.displayPrefectureNoDataTarget.innerText = '指定なし'
        this.resetHeightItem(this.displayPrefectureNoDataTarget)
      }
    } else {
      areas.forEach(ar => {
        const target = document.querySelector(`#check_all_${ar}`)
        const prefIsChecked = _prefChecked.filter(e => e.dataset.itemParentTarget == target.dataset.itemRootTarget)
        if (target.checked) {
          text.push(target.dataset.areaNameCheckAll)
          areaTypes.push('area')
          areaIds.push(target.dataset.itemRootTarget)
        } else {
          text.push(...prefIsChecked.map(p => p.parentNode.previousSibling.firstChild.textContent))
          areaTypes.push(...prefIsChecked.map(p => 'pref'))
          areaIds.push(...prefIsChecked.map(p => 'empty'))
        }
        ids.push(...prefIsChecked.map(p => p.value))
      })
      if (modalTarget.getAttribute('data-action-from') == 'quick-search') {
        if(this.suggestionsSearchController) {
          this.suggestionsSearchController.hiddenQuickSearchInputPrefectureCodesTarget.value = ids
          this.suggestionsSearchController.hiddenQuickSearchInputCityCodesTarget.value = ''
        }
        this.setPrefectureBackToFrom(text, areaTypes, areaIds)
        $('.input-field-pref-city-container .cks-label-for-suggest').addClass('active');
      } else {
        searchParams.prefectureCodes = ids
        this.hiddenInputPrefectureCodesTarget.value = ids

        this.displayPrefectureSelectedTarget.innerText = text.join('、')
        this.displayPrefectureNoDataTarget.innerText = ''
        this.increateHeightItem(this.displayPrefectureNoDataTarget)
      }
    }
  }

  setPrefectureBackToFrom(values, areaTypes, areaIds) {
    $('.suggest-pref-city').empty()
    $.each(values, function(index, value) {
      if (areaTypes[index] == 'pref') {
        var $newOption = $("<option selected='selected' data-type='pref'></option>").val(value).text(value)
      } else if (areaTypes[index] == 'area') {
        var ids = []
        $(`[data-item-parent-target='${areaIds[index]}']`).each(function() {
          ids.push($(this).val())
        });
        var $newOption = $("<option selected='selected' data-type='area' data-ids='" + ids.join(',') + "'></option>").val(value).text(value)
      }

      $(".suggest-pref-city").append($newOption).trigger('change')
    })
  }

  setCityCodes(el) {
    const cityChecked = this.cbCityItemTargets.filter(x => x.checked)

    if (cityChecked.length == 0) {
      this.removeValueCity()
    } else {
      const values = cityChecked.map(mk => mk.value)
      const text = cityChecked.map(mk => mk.parentElement.nextElementSibling.firstChild.textContent).join('、')
      searchParams.cityCodes = values
      this.hiddenInputCityCodesTarget.value = values
      this.displayCitySelectedTarget.innerText = text
      this.displayCityNoDataTarget.innerText = ''
      this.increateHeightItem(this.displayCityNoDataTarget)
    }
    this.closeModal(el)
  }

  removeValueCity() {
    searchParams.cityCodes = []
    this.hiddenInputCityCodesTarget.value = ''
    this.displayCitySelectedTarget.innerText = ''
    this.displayCityNoDataTarget.innerText = '指定なし'
    this.resetHeightItem(this.displayCityNoDataTarget)
  }

  setEquips(el) {
    const target = this.cbEquipItemTargets.filter(e => e.checked)
    const text   = target.map(e => e.parentElement.nextSibling.textContent)

    let equipSelected = target.filter(e => e.getAttribute('category') === 'equip')
    let transmissionTypeSelected = target.filter(e => e.getAttribute('category') === 'transmission_type')

    searchParams.equips = equipSelected.map(e => e.value)
    searchParams.transmissionTypes = transmissionTypeSelected.map(e => e.value)

    this.displayEquipNoDataTarget.innerText = ''
    if(this.hasHiddenInputEquipsTarget) this.hiddenInputEquipsTarget.value = searchParams.equips;
    if(this.hasHiddenInputTransmissionTypesTarget) this.hiddenInputTransmissionTypesTarget.value = searchParams.transmissionTypes;
    this.displayEquipSelectedTarget.innerText = text.join('、')
    this.increateHeightItem(this.displayEquipNoDataTarget)
    this.closeModal(el)
  }

  increateHeightItem(target) {
    target.parentElement.parentElement.classList.add('min-height-50')
  }

  resetHeightItem(target) {
    target.parentElement.parentElement.classList.remove('min-height-50')
  }

  resetAllForm() {
    const listLiMenuItems = [this.menuItemCarModelTarget, this.menuItemGradeTarget, this.menuItemCityTarget]
    listLiMenuItems.forEach(t => {
      t.setAttribute('data-action', '')
      t.parentElement.classList.add('inactive')
    })

    const hiddenInputs = [this.hiddenInputPriceMinTarget, this.hiddenInputPriceMaxTarget, this.hiddenInputMakerCodesTarget,
                          this.hiddenInputCarModelCodesTarget, this.hiddenInputBodytypeCodeTarget, this.hiddenInputGradeCodesTarget, this.hiddenInputFmcCodesTarget,
                          this.hiddenInputPrefectureCodesTarget, this.hiddenInputCityCodesTarget, this.hiddenInputEquipsTarget,
                          this.hiddenInputKeywordTarget, this.hiddenInputColorCodesTarget, this.hiddenInputBodyTypeDetailNamesTarget,
                          this.hiddenInputEngineTypeTarget, this.hiddenInputCarEquipCodesTarget, this.hiddenInputCampingCarTarget,
                          this.hiddenInputCommercialCarsTarget, this.hiddenInputTransmissionTypesTarget]
    hiddenInputs.forEach(t => t.value = '')

    const elementsDisplayValue = [this.displayPriceMinSelectedTarget, this.displayPriceMaxSelectedTarget, this.displayMakerSelectedTarget,
                                  this.displayCarModelSelectedTarget, this.displayBodytypeSelectedTarget, this.displayGradeSelectedTarget,
                                  this.displayPrefectureSelectedTarget, this.displayCitySelectedTarget, this.displayEquipSelectedTarget,
                                  this.displayColorSelectedTarget, this.displaySpecificationSelectedTarget]
    elementsDisplayValue.forEach(t => t.innerText = '')


    const elementsDisplayNoData = [this.displayMakerNoDataTarget, this.displayCarModelNoDataTarget, this.displayBodytypeNoDataTarget,
                                   this.displayGradeNoDataTarget, this.displayPrefectureNoDataTarget, this.displayCityNoDataTarget,
                                   this.displayEquipNoDataTarget, this.displayColorNoDataTarget, this.displaySpecificationNoDataTarget]
    elementsDisplayNoData.forEach(t => {
      t.innerText = '指定なし'
      this.resetHeightItem(t)
    })

    this.displayPriceMinSelectedTarget.innerText = `Min ~`
    this.displayPriceMinSelectedTarget.classList.remove('selected')
    this.displayPriceMaxSelectedTarget.innerText = 'Max'
    this.displayPriceMaxSelectedTarget.classList.remove('selected')

    $(".menu-search-suggest-keyword").val(null).trigger("change");

    this.removeOldModal('.cks-modal.car-modal-options')
    this.removeOldModal('.cks-modal.city-options')
    this.removeOldModal('.cks-modal.car-modal-options')

    document.querySelectorAll('.list-modal__container input[type="checkbox"]').forEach(e => e.checked = false)
    searchParams.reset()
  }
  
  get suggestionsSearchController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById('search-box'), "suggestions-search")
  }

  setMakerTopPage(el) {
    const makerChecked = this.cbMakerTargets.filter(e => e.checked)
    this.setMakerCodes(makerChecked)
    this.closeModal(el)
  }

  closeSearchMenuTop() {
    document.querySelector("[data-menu-search='overlay-close']").classList.remove('active');
    document.querySelector("[data-menu-search='menu-search-container']").classList.remove('active');
    document.body.style.overflow = 'auto'

    let priceMinText      = this.displayPriceMinSelectedTarget.textContent.slice(0, -2)
    let priceMaxText      = this.displayPriceMaxSelectedTarget.textContent
    let makerText         = this.displayMakerSelectedTarget.textContent
    let bodytypeText      = this.displayBodytypeSelectedTarget.textContent
    let equipText         = this.displayEquipSelectedTarget.textContent
    let colorText         = this.displayColorSelectedTarget.textContent
    let specificationText = this.displaySpecificationSelectedTarget.textContent

    let fullText     = [`${priceMinText}以上`, `${priceMaxText}以下`, makerText,
                        bodytypeText, colorText, equipText, specificationText].filter(t => t !== '' && t !== 'Min以上' && t !== 'Max以下').join('、')
    if (fullText !== '') document.querySelector('.price-range-input span.mdc-floating-label').textContent = fullText

    this.suggestionsSearchController.hiddenQuickSearchInputPriceMinTarget.value              = searchParams.priceMin ?? ''
    this.suggestionsSearchController.hiddenQuickSearchInputPriceMaxTarget.value              = searchParams.priceMax ?? ''
    this.suggestionsSearchController.hiddenQuickSearchInputMakerCodesTarget.value            = searchParams.makerCodes
    this.suggestionsSearchController.hiddenQuickSearchInputBodytypeCodeTarget.value          = searchParams.bodytypeCode ?? ''
    this.suggestionsSearchController.hiddenQuickSearchInputEquipsTarget.value                = searchParams.equips
    this.suggestionsSearchController.hiddenQuickSearchInputColorCodesTarget.value            = searchParams.colorCodes
    this.suggestionsSearchController.hiddenQuickSearchInputBodyTypeDetailNamesTarget.value   = searchParams.bodyTypeDetailNames
    this.suggestionsSearchController.hiddenQuickSearchInputEngineTypeTarget.value            = searchParams.engineType
    this.suggestionsSearchController.hiddenQuickSearchInputCarEquipCodesTarget.value         = searchParams.carEquipCodes
    this.suggestionsSearchController.hiddenQuickSearchInputCampingCarTarget.value            = searchParams.campingCar
    this.suggestionsSearchController.hiddenQuickSearchInputCommercialCarsTarget.value        = searchParams.commercialCars
    this.suggestionsSearchController.hiddenQuickSearchInputTransmissionTypesTarget.value     = searchParams.transmissionTypes
  }

  setColorCodes(el) {
    const target = this.cbColorTargets.filter(e => e.checked)

    if (target.length == 0) {
      searchParams.colorCodes = []
      if(this.hasHiddenInputColorCodesTarget) this.hiddenInputColorCodesTarget.value = '';
      this.displayColorSelectedTarget.innerText = ''
      this.displayColorNoDataTarget.innerText = '指定なし'
      this.resetHeightItem(this.displayColorNoDataTarget)

    } else {
      const text = target.map(mk => mk.parentElement.previousSibling.firstChild.nextSibling.innerText)
      const values = target.map(e => e.value)

      searchParams.colorCodes = values
      this.displayColorNoDataTarget.innerText = ''
      if (this.hasHiddenInputColorCodesTarget) this.hiddenInputColorCodesTarget.value = values
      this.displayColorSelectedTarget.innerText = text.join('、')
      this.increateHeightItem(this.displayColorNoDataTarget)
    }

    this.closeModal(el)
  }

  setSpecifications(el) {
    const target = this.cbSpecificationTargets.filter(e => e.checked)

    if (target.length == 0) {
      searchParams.bodyTypeDetailNames = []
      searchParams.engineType = []
      searchParams.carEquipCodes = []
      searchParams.campingCar = []
      searchParams.commercialCars = []

      if (this.hasHiddenInputBodyTypeDetailNamesTarget) this.hiddenInputBodyTypeDetailNamesTarget.value = '';
      if (this.hasHiddenInputEngineTypeTarget) this.hiddenInputEngineTypeTarget.value = '';
      if (this.hasHiddenInputCarEquipCodesTarget) this.hiddenInputCarEquipCodesTarget.value = '';
      if (this.hasHiddenInputCampingCarTarget) this.hiddenInputCampingCarTarget.value = '';
      if (this.hasHiddenInputCommercialCarsTarget) this.hiddenInputCommercialCarsTarget.value = '';

      this.displaySpecificationSelectedTarget.innerText = ''
      this.displaySpecificationNoDataTarget.innerText = '指定なし'
      this.resetHeightItem(this.displaySpecificationNoDataTarget)
    } else {
      const text = target.map(e => e.parentElement.nextSibling.textContent)

      this.displaySpecificationNoDataTarget.innerText = ''
      this.assignmentSpecification(target)
      this.displaySpecificationSelectedTarget.innerText = text.join('、')
      this.increateHeightItem(this.displaySpecificationNoDataTarget)
    }
    this.closeModal(el)
  }

  assignmentSpecification(target) {
    let bodyTypeDetailNameSelected = target.filter(e => e.getAttribute('category') === 'body_type_detail_name')
    let engineTypeSelected         = target.filter(e => e.getAttribute('category') === 'engine_type')
    let carEquipCodesSelected      = target.filter(e => e.getAttribute('category') === 'car_equip')
    let campingCarSelected         = target.filter(e => e.getAttribute('category') === 'camping_car')
    let commercialCarSelected      = target.filter(e => e.getAttribute('category') === 'commercial_car')

    searchParams.bodyTypeDetailNames = bodyTypeDetailNameSelected.map(e => e.value)
    searchParams.engineType          = engineTypeSelected.map(e => e.value)
    searchParams.carEquipCodes       = carEquipCodesSelected.map(e => e.value)
    searchParams.campingCar          = campingCarSelected.map(e => e.value)
    searchParams.commercialCars      = commercialCarSelected.map(e => e.value)

    if (this.hasHiddenInputBodyTypeDetailNamesTarget) this.hiddenInputBodyTypeDetailNamesTarget.value = searchParams.bodyTypeDetailNames
    if (this.hasHiddenInputEngineTypeTarget) this.hiddenInputEngineTypeTarget.value = searchParams.engineType
    if (this.hasHiddenInputCarEquipCodesTarget) this.hiddenInputCarEquipCodesTarget.value = searchParams.carEquipCodes
    if (this.hasHiddenInputCampingCarTarget) this.hiddenInputCampingCarTarget.value = searchParams.campingCar
    if (this.hasHiddenInputCommercialCarsTarget) this.hiddenInputCommercialCarsTarget.value = searchParams.commercialCars
  }

  appendTextDisplaySpecification(appendText) {
    if (this.displaySpecificationSelectedTarget.innerText) {
      return  this.displaySpecificationSelectedTarget.innerText.concat(', ', appendText)
    } else {
      return  appendText
    }
  }

  appendTextDisplayEquip(appendText) {
    if (this.displayEquipSelectedTarget.innerText) {
      return  this.displayEquipSelectedTarget.innerText.concat(', ', appendText)
    } else {
      return  appendText
    }
  }
}
