const ribbonControllable = () => {
  if (!document.querySelector('.inquiry-ribbon')) return;

  const ribbonStep1 = document.querySelector('.inquiry-ribbon__step.inquiry-ribbon__step--1');
  const ribbonStep2 = document.querySelector('.inquiry-ribbon__step.inquiry-ribbon__step--2');
  const ribbonStep2Active = document.querySelector('.inquiry-ribbon__step.inquiry-ribbon__step--2.active');
  const ribbonStep3Active = document.querySelector('.inquiry-ribbon__step.inquiry-ribbon__step--3.active');

  if (ribbonStep2Active != null && ribbonStep3Active != null) {
    ribbonStep1.style.setProperty('--bg-step-1', '#2A68AA')
    ribbonStep2.style.setProperty('--bg-step-2', '#17508E')
  }

  if (ribbonStep2Active && !ribbonStep3Active) {
    ribbonStep1.style.setProperty('--bg-step-1', '#17508E')
  }
}

export default ribbonControllable;
