import {MDCDialog} from '@material/dialog';

if (document.querySelector('.not-found-dialog') != null) {
  const dialog = new MDCDialog(document.querySelector('.not-found-dialog'));

  const buttonRipple = document.querySelector('.not-found-dialog__footer .mdc-button');

  dialog.open();

  buttonRipple.addEventListener('click', (event) => {
    dialog.close()
  });
}
