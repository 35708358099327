import { Controller } from 'stimulus'
import {ajax} from '@rails/ujs'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['comboBtnBackAndInquiry', 'favoriteButton', 'carGlobalKey', 'recommend']

  initialize() {
    this.recommendCar()
    this.validateInquiry()
    const _this = this;

    const el = document.querySelector('.main-container')
    el.addEventListener('scroll', (e) => {
      _this.activeComboButton(e);
    })
  }

  activeComboButton(e) {
    let scroll = e.target.className == 'main-container' ? e.target.scrollTop : window.scrollY;
    const herderHeight = 140;

    if (scroll >= herderHeight) {
      this.comboBtnBackAndInquiryTarget.classList.add('active');
    } else {
      this.comboBtnBackAndInquiryTarget.classList.remove('active');
    }
  }

  favorite() {
    let _this = this
    let data  = { global_key: _this.favoriteButtonTarget.getAttribute('data-global-key') }

    ajax({
      url: `/cars/ajax_add_favorite`,
      type: 'POST',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        if (response.status) {
          _this.favoriteButtonTarget.setAttribute('data-action', 'car-detail#unFavorite')
          _this.favoriteButtonTarget.classList.add('mdc-icon-button--on')
        } else {
          _this.favoriteButtonTarget.setAttribute('data-action', 'car-detail#favorite')
          _this.favoriteButtonTarget.classList.remove('mdc-icon-button--on')
          alert(response.message)
        }
      }
    })
  }

  unFavorite() {
    let _this = this
    let data  = { global_key: _this.favoriteButtonTarget.getAttribute('data-global-key') }

    ajax({
      url: `/cars/ajax_delete_favorite`,
      type: 'POST',
      dataType: 'jsonp',
      data: new URLSearchParams(data).toString(),
      success: function () {
        _this.favoriteButtonTarget.setAttribute('data-action', 'car-detail#favorite')
        _this.favoriteButtonTarget.classList.remove('mdc-icon-button--on')
      }
    })
  }

  recommendCar() {
    let _this = this
    let data  = { global_key: _this.carGlobalKeyTarget.getAttribute('data-global-key') }
    let recommendList = $(_this.recommendTarget)
    ajax({
      url: `/cars/ajax_recommend_list`,
      type: 'POST',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        if (response != '') {
          recommendList.html(response.body.children);

          const detail_recommend_target = document.querySelector('.ajax-recommends #detailRecommendImp')
          if (detail_recommend_target) {
            recommendList[0].style.setProperty('padding-bottom', '20px');
            UserLog.detail_recommend_imp(detail_recommend_target.value);
            UserLog.pageTrack();
          }
        }
      }
    })
  }

  validateInquiry() {
    var form = $('form.form-inquiry')

    $(document).on('click', '.form-inquiry__submit', function () {
      var reasonInquiries = [
        $('#inp_inquiry_stock').is(':checked'),
        $('#inp_inquiry_estimate').is(':checked'),
        $('#inp_inquiry_visit_shop').is(':checked'),
        $('#inp_inquiry_car_status').is(':checked'),
        $('#inp_inquiry_other').is(':checked')
      ]
      if (reasonInquiries.reduce((a, b) => a || b, null)) {
        form.submit();
      } else {
        form.find('.error-box').html('必ず1つはチェックを入れてください。')
      }
    })
  }
}
