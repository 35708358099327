// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

import 'controllers'
import 'stylesheets/application'

import 'packs/slick-slider'
import 'packs/user_log'
import 'packs/screen_detector'
import 'packs/car'
import 'packs/menu'
import 'packs/404'
import 'packs/home'
import 'packs/search_params'
import '../components/index'
import 'packs/cs-inquiry-chat'

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
    .then(function(reg) {
      console.log('[Companion]', 'Service worker registered!');
    });
}

UserLog.access_log();

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
