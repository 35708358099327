import { Controller } from 'stimulus'
import { ajax } from '@rails/ujs'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['suggestedCars', 'conditions']

  removeSearchCondition(event) {
    const currentItem = event.currentTarget
    let dataRemoveKey  = currentItem.getAttribute('data-remove-key')
    $(`#suggested-tag-${dataRemoveKey}`).remove()

    let data  = { conditions: this.conditionsTarget.value, remove_key: dataRemoveKey }
    let recommendList = this.suggestedCarsTarget

    ajax({
      url: `/home/ajax_search_history_recommend_cars`,
      type: 'GET',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        if (response != '')
          recommendList.innerHTML = response.body.innerHTML
      }
    })
  }
}
