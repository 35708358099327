import $ from 'jquery'
import Select2 from "select2"

const inputFieldKeywordContainer = document.querySelector('.input-field-keyword-container')
const inputFieldPrefCityContainer = document.querySelector('.input-field-pref-city-container')
const inputFieldMenuSearchSuggestKeywordContainer = document.querySelector('.input-field-menu-search-suggest-keyword-container')

const arrElements = [inputFieldKeywordContainer, inputFieldPrefCityContainer, inputFieldMenuSearchSuggestKeywordContainer].filter(e => e);

if (arrElements.length > 0) {
  arrElements.forEach(el => {
    el.addEventListener('click', (event) => {
      const label = el.querySelector('.cks-label-for-suggest')
      labelUp(label)
    });

    $(document).ready(function () {
      const currentTextArea = el.getElementsByTagName('textarea')[0]
      currentTextArea.addEventListener('blur', (e) => {
        const parent = e.target.closest('.input-field-container')
        const label = parent.querySelector('.cks-label-for-suggest.active')
        labelDown(el, parent, label)
      });
    });
  })
}

function labelUp(target) {
  if (target.parentElement.getElementsByTagName('textarea')[0].style.caretColor == 'transparent') return;

  target.classList.add('active')
  target.parentElement.getElementsByTagName('textarea')[0].focus();
}

function labelDown(el, parent, label) {
  $(document).ready(function () {
    let currentTextArea = el.getElementsByTagName('textarea')[0]
    let listSuggets = parent.querySelectorAll('ul.select2-selection__rendered li')
    let isFocus = $(currentTextArea).is(':focus')
    if ((listSuggets.length === undefined || listSuggets.length === 0) && currentTextArea.value === '') {
      if (label === null) return;
      if (isFocus) return;

      label.classList.remove('active')
      $(currentTextArea).blur();
    } else {
      let selector = (label === null || label === undefined) ? '.cks-label-for-suggest' : '.cks-label-for-suggest.active'
      parent.querySelector(selector).classList.add('active')
    }
  });
}

$(document.body).on('change', '.suggest-pref-city', function() {
  let _this = this
  $(document.body).on('blur', '.input-field-pref-city-container span.select2-container--classic textarea', function() {
    let suggestItems = _this.nextElementSibling.querySelectorAll('ul li')
    if (suggestItems.length > 0) return;

    _this.nextElementSibling.nextElementSibling.classList.remove('active')
  });
});
