import { MDCTextField } from '@material/textfield';
import { MDCRipple } from '@material/ripple';

const buttons = [].map.call(document.querySelectorAll('.mdc-button'), function(el) {
  return new MDCRipple(el);
});
const textFields = [].map.call(document.querySelectorAll('.mdc-text-field'), function(el) {
  return new MDCTextField(el);
});

const inputTriggerMenuSearch = document.getElementById('input-trigger-menu-search');
if (inputTriggerMenuSearch != null) {
  inputTriggerMenuSearch.classList.remove('mdc-text-field--disabled');
}

if (window.innerWidth > 649) {
  const container = document.querySelector('.main-container')

  window.addEventListener('load', (event) => {
    const popup404 = document.getElementsByClassName('mdc-dialog not-found-dialog mdc-dialog--open')

    if (popup404.length > 0) {
      container.style.setProperty('overflow-y', 'hidden', 'important');
      eventHandle(['btn-close-popup-404', 'overlay-close-popup-404'])
    } else {
      scrollOutside()
    }
  });

  function eventHandle(classnames) {
    classnames.forEach(el => { document.getElementById(el).addEventListener('click', () => { setStyle();scrollOutside(); }) });
  }

  function setStyle() { container.style.setProperty('overflow-y', 'auto', 'important'); }

  function scrollOutside() {
    document.addEventListener('wheel', event => {
      const preventLeavingInquiryPagePopup = document.getElementById('popup_prevent_leaving_inquiry_page')
      if (preventLeavingInquiryPagePopup && !preventLeavingInquiryPagePopup.className.includes('d-none')) return;

      if (event.target.closest('.l-contents-pc') !== null || event.target.className === 'wrapper') {
        container.scrollTo({ top: event.deltaY + container.scrollTop })
      }
    });
  }
}
