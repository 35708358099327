import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['conversation', 'botMessageContainer', 'userMessageContainer', 'botMessage', 'userMessageQuestion', 'userMessage', 'submitBtn' , 'showMenuBtn']

  initialize() {
    this.processes = {
      step_1: {
        user_message: { passive: 'お手伝いしてください。', active: '自分で検索します。' }
      },
      step_2_passive: {
        bot_messages: ['分かりました！では3つご質問をさせていただき、簡単なご提案をさせていただきますね！', 'ご家族での利用がメインですか？']
      },
      step_2_active: {
        bot_messages: ['分かりました。下のボタンから車を探して、気に入ったら♡を押すとお気に入りを登録できます。', 'それでは行ってらっしゃいませ。']
      }
    }
  }

  pageShow () {
    this.resetValue()
  }

  choose_search_type(event) {
    let currentStep = parseInt(event.currentTarget.closest('[data-step]').dataset.step)
    let options = {
      currentStep: currentStep,
      nextStep: currentStep + 1,
      inputValue: event.currentTarget.value
    }
    this.renderUserMessage(event, options)
    this.renderBotMessage(options)
    if (options.inputValue == 'active') {
      this.renderSubmitBtn(options)
    } else {
      this.hideSubmitBtn()
      this.renderUserMessageQuestion(options)
    }
    this.scrollDown()
  }

  answer(event) {
    let currentStep = parseInt(event.currentTarget.closest('[data-step]').dataset.step)
    let options = {
      currentStep: currentStep,
      nextStep: currentStep + 1,
      inputValue: event.currentTarget.value
    }
    this.renderBotMessage(options)
    this.renderUserMessageQuestion(options)
    this.scrollDown()
  }

  select(event) {
    let currentStep = parseInt(event.currentTarget.closest('[data-step]').dataset.step)
    let options = {
      currentStep: currentStep,
      nextStep: currentStep + 1,
      inputValue: event.currentTarget.value
    }
    this.renderBotMessage(options)
    this.renderSubmitBtn(options)
    this.scrollDown()
  }

  submit(event) {
    let data = $(event.currentTarget).serializeArray().reduce((object, attr) => { object[attr.name] = attr.value; return object; }, {});
    if (data.search_type == 'active') {
      document.querySelectorAll('input, select').forEach(input => input.disabled = true)
    } else {
      this.updateFormData(event, data)
    }
  }

  updateFormData(event, data) {
    let form = event.currentTarget
    if (data.price == 'max_1000000') {
      document.querySelector('input#price_max').value = '1000000'
      document.querySelector('input#price_min').disabled = true
    } else {
      document.querySelector('input#price_min').value = '1000000'
      document.querySelector('input#price_max').disabled = true
    }
    document.querySelectorAll('input[name="search_type"], input[name="price"]').forEach(input => input.disabled = true)
  }

  resetValue() {
    $('input[type=radio]').prop('checked', false);
    $('select#prefecture option:first').prop('selected', true);
  }

  scrollDown() {
    if (window.innerWidth > 649) {
      $('.main-container').animate({ scrollTop: $(document).height() }, 1000)
    } else {
      $('html, body').animate({ scrollTop: $(document).height() }, 1000)
    }
  }

  renderUserMessage(event, options) {
    event.currentTarget.closest('.user-message-question').classList.add('hidden')
    let content = this.processes['step_' + options.currentStep]['user_message'][options.inputValue]
    this.userMessageTarget.querySelector("span.inner-content").innerHTML = content
    this.userMessageTarget.classList.remove('hidden')
  }

  renderUserMessageQuestion(options) {
    let userMessageContainer = this.conversationTarget.querySelector('.chatbox__user-message-container[data-step="' + options.nextStep + '"]')
    userMessageContainer.classList.remove('hidden')
  }

  renderBotMessage(options) {
    let botContainer = this.conversationTarget.querySelector('.chatbox__bot-message-container[data-step="' + options.nextStep + '"]')
    if (options.currentStep == 1) {
      let messages = this.processes['step_' + options.nextStep + '_' + options.inputValue].bot_messages
      botContainer.querySelectorAll('.bot-message').forEach((element, index) => {
        element.querySelector('span.inner-content').innerText = messages[index]
      })
    }
    botContainer.classList.remove('hidden')
  }

  renderSubmitBtn(options) {
    if (options.currentStep == '1') { // Button Show Menu Search
      this.submitBtnTarget.classList.add('hidden');
      this.showMenuBtnTarget.classList.remove('hidden');
    } else { // Button Submit To Page Search
      this.submitBtnTarget.classList.remove('hidden');
      this.showMenuBtnTarget.classList.add('hidden');
    }

  }

  edit(event) {
    let currentUserMessageContainer = event.currentTarget.closest('.chatbox__user-message-container')
    currentUserMessageContainer.querySelector('.user-message-question').classList.remove('hidden')
    this.hideAllMessages()
    currentUserMessageContainer.querySelector('.user-message').classList.add('hidden')
    this.resetValue()
  }

  hideAllMessages() {
    let currentContainer = event.currentTarget.closest('.chatbox__user-message-container ')
    this.getNextSiblings(currentContainer).forEach(element => element.classList.add('hidden'))
    this.hideSubmitBtn()
  }

  hideSubmitBtn() {
    this.submitBtnTarget.classList.add('hidden');
    this.showMenuBtnTarget.classList.add('hidden');
  }

  getNextSiblings(element, filter) {
    let sibs = [];
    while (element = element.nextSibling) {
      if (element.nodeType === 3) continue;
      if (!filter || filter(element)) sibs.push(element);
    }
    return sibs;
  }
}
